import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BookDetailComponent from "./BookDetailComponent";
import Loading from "../Common/Loading";

const BooksScrollable = ({ books, sectionTitle, isLoading }) => {
  const [openBook, setOpenBook] = React.useState(null);

  const settings = {
    arrows: true,
    focusOnSelect: true,
    swipeToSlide: true,
    touchMove: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    pauseOnHover: true,
    pauseOnFocus: true,
    pauseOnDotsHover: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: { slidesToShow: 4, slidesToScroll: 1 }, // Adjust for larger screens
      },
      {
        breakpoint: 1024, // Specifically for medium screen sizes like 1024px
        settings: { slidesToShow: 4, slidesToScroll: 1 }, // Display 4 items for 1024px width
      },
      {
        breakpoint: 800,
        settings: { slidesToShow: 3, slidesToScroll: 1 }, // Smaller screens show 3 items
      },
      {
        breakpoint: 600,
        settings: { slidesToShow: 2, slidesToScroll: 1 }, // Smaller screens show 2 items
      },
      {
        breakpoint: 400, // For mobile screens
        settings: { slidesToShow: 1, slidesToScroll: 1 }, // Show one book on very small screens
      },
    ],
  };

  return (
    <div>
      <h2 className="text-2xl md:text-3xl font-bold ">{sectionTitle}</h2>
      {isLoading ? (
        <Loading />
      ) : (
        <Slider {...settings}>
          {books.map((book) => (
            <div
              key={book.id}
              className="p-2 focus:outline-none h-full mb-2"
              onClick={() => setOpenBook(book)}
              tabIndex={0}
              role="button"
              aria-label={`View details for ${book.title}`}
              onKeyDown={(event) => event.key === "Enter" && setOpenBook(book)}
            >
              <div
                className="book-item border rounded-lg shadow-lg flex flex-col hover:shadow-xl cursor-pointer transition-shadow"
                style={{ height: "auto", maxWidth: "100%" }}
              >
                <div className="flex justify-center items-center p-2 md:p-4 mt-2 bg-gray-100 rounded-lg">
                  {book.image_url ? (
                    <img
                      src={book.image_url}
                      alt={book.title}
                      className="w-full h-48 sm:h-56 md:h-64 lg:h-72 xl:h-80 rounded-lg object-cover"
                      // Ensures the image fits its container without distortion
                    />
                  ) : (
                    <div className="w-full flex h-48 sm:h-56 md:h-64 lg:h-72 xl:h-80 rounded-lg object-cover justify-center items-center">
                      <p className="text-lg font-semibold text-gray-700 text-center">
                        {book.title}
                      </p>
                    </div>
                  )}
                </div>
                <div className="p-2 flex-1">
                  <div className="text-md md:text-lg font-bold truncate">
                    {book.title}
                  </div>
                  <p className="text-xs md:text-sm text-gray-700 truncate">
                    {book.author}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      )}

      {openBook && (
        <BookDetailComponent
          book={openBook}
          onClose={() => setOpenBook(null)}
        />
      )}
    </div>
  );
};

export default BooksScrollable;
