import { useEffect, useState } from "react";
import AddressSelection from "./AddressComponent";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { getBook } from "../Books/BooksUtil";
import ConfirmationPage from "./ConfirimationPage";
import { getSubscriptionDetails } from "../User/UserUtils";
import Loading from "../Common/Loading";

const OrderComponent = () => {
  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  const book_id = queryParams.book_id || "";
  const [step, setStep] = useState(0);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [booksDetails, setBooksDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [subscriptionDetail, setSubscriptionDetail] = useState([]);
  const navigate = useNavigate();

  const handleAddressChange = (address) => {
    setSelectedAddress(address);
  };

  const getBookFromId = async () => {
    await getBook({ book_id, setBooksDetails });
  };

  const getSubscriptionDetail = async () => {
    const s = await getSubscriptionDetails({ setSubscriptionDetail, setIsLoading });
  };

  useEffect(() => {
    getSubscriptionDetail();

    if (book_id !== "") {
      getBookFromId();
    }

  }, []);

  useEffect(() => {}, []);

  const handleContinue = () => {
    if (selectedAddress !== null) {
      setStep(1);
    } else {
      alert("Please select an address to continue.");
    }
  };

  return (
    <div className="h-full bg-gray-50 overflow-y-auto w-full   p-4">
      {isLoading && <Loading />}
      {subscriptionDetail === null || subscriptionDetail.length === 0 ? (
        <ErrorNoValidSubscriptionFound navigate={navigate} />
      ) : (
        <>
          {book_id === "" ? (
            <ErrorBookNotFound navigate={navigate} />
          ) : (
            <div className="flex h-full w-full justify-center">
              {step === 0 && (
                <AddressSelection
                  selectedAddress={selectedAddress}
                  handleAddressChange={handleAddressChange}
                  onContinue={handleContinue}
                />
              )}

              {step === 1 && (
                <div>
                  <ConfirmationPage
                    setStep={setStep}
                    book={booksDetails}
                    address={selectedAddress}
                    navigate={navigate}
                  />
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

const ErrorBookNotFound = ({ navigate }) => {
  return (
    <>
      <div className="flex justify-center items-center h-1/3">
        <div className="shadow-xl p-4   bg-slate-300 rounded-md justify-center items-center">
          <div>Please Select the book to continue.</div>
          <div className="flex justify-center">
            <div
              className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded "
              onClick={() => navigate(-1)}
            >
              Go Back
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderComponent;

const ErrorNoValidSubscriptionFound = ({ navigate }) => {
  return (
    <div className="flex justify-center items-center h-full">

        <div className="flex justify-center items-center h-full">
          <div className="max-w-lg w-full bg-white rounded-xl shadow-lg p-6 transition duration-300 ease-in-out hover:shadow-xl">
            <div className="flex flex-col items-center">
              <h1 className="text-start mb-4 font-semibold text-2xl text-blue-800">
                Oops!! No Active Subscription
              </h1>
              <div className="text-center mb-4">
                You don't have any active subscription.
              </div>
              <div className="text-center mb-4">
                Subscribe to a plan now to get started:
              </div>
              <div className="flex justify-between w-full">
                <button
                  className="mt-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                  onClick={() => navigate(-1)}
                >
                  Go Back
                </button>
                <a href="/subscription" className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                  Browse Plans
                </a>
              
              </div>
              
            </div>
          </div>

      
      </div>
    </div>
  );
};

