import { BookModel, SearchRequest } from "../Serializers/SerializerModels";
import { fetchPostData } from "../Utilities/FetchUtils";
export const getBookByGenre = async (genre, setBooks, setIsLoading) => {
  setIsLoading(true);
  const { response, error } = await fetchPostData(
    "books/get_by_genre",
    new SearchRequest(genre,10)
  );
  if (response) {
    const books = response.book_details.map((book) => new BookModel(book));
    setBooks(books);
  } else {
    return [];
  }
  setIsLoading(false);
};
