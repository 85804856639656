import React, { useState } from "react";
import BookDetailComponent from "./BookDetailComponent";
import { truncateText } from "./BooksUtil";

const BooksComponent = ({ booksDetails }) => {
  const [selectedBook, setSelectedBook] = useState(null);

  const selectBook = (book) => {
    setSelectedBook(book);
  };

  return (
    <div className="flex flex-col gap-4 overflow-auto max-h-full p-4">
      {booksDetails.map((book, index) => (
        <div
          key={book.id}
          className="flex flex-col md:flex-row items-center bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow cursor-pointer mb-4 p-2"
          onClick={() => selectBook(book)}
        >
          <img
            className="w-36 md:w-40 h-auto rounded-t-lg md:rounded-none md:rounded-l-lg"
            src={book.image_url}
            alt={book.title}
          />
          <div className="p-4 flex-grow">
            <h3 className="text-lg md:text-xl font-semibold">{book.title}</h3>
            <p className="text-gray-800 text-sm md:text-md ">{book.author}</p>
            <p className="text-gray-600 text-xs md:text-sm mt-2">
              {truncateText(book.description, 100)}
            </p>
          </div>
        </div>
      ))}
      {selectedBook && (
        <BookDetailComponent
          book={selectedBook}
          onClose={() => setSelectedBook(null)}
        />
      )}
    </div>
  );
};

export default BooksComponent;
