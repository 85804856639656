export const quotes = [
  {
    quote: "It is never too late to be what you might have been.",
    book: "Middlemarch by George Eliot",
  },
  {
    quote: "Not all those who wander are lost.",
    book: "The Fellowship of the Ring by J.R.R. Tolkien",
  },
  {
    quote:
      "You have brains in your head. You have feet in your shoes. You can steer yourself any direction you choose.",
    book: "Oh, The Places You'll Go! by Dr. Seuss",
  },
  {
    quote: "What is essential is invisible to the eye.",
    book: "The Little Prince by Antoine de Saint-Exupéry",
  },
  {
    quote:
      "Remember that wherever your heart is, there you will find your treasure.",
    book: "The Alchemist by Paulo Coelho",
  },
  {
    quote:
      "Until I feared I would lose it, I never loved to read. One does not love breathing.",
    book: "To Kill a Mockingbird by Harper Lee",
  },
  {
    quote: "We accept the love we think we deserve.",
    book: "The Perks of Being a Wallflower by Stephen Chbosky",
  },
  {
    quote: "It does not do well to dwell on dreams and forget to live.",
    book: "Harry Potter and the Sorcerer's Stone by J.K. Rowling",
  },
  {
    quote:
      "Sometimes the right thing feels all wrong until it is over and done with.",
    book: "The Stand by Stephen King",
  },
  {
    quote:
      "Real courage is when you know you're licked before you begin, but you begin anyway and see it through no matter what.",
    book: "To Kill a Mockingbird by Harper Lee",
  },
  {
    quote: "The only way out of the labyrinth of suffering is to forgive.",
    book: "Looking for Alaska by John Green",
  },
  {
    quote: "I am not afraid of storms, for I am learning how to sail my ship.",
    book: "Little Women by Louisa May Alcott",
  },
  {
    quote:
      "All we have to decide is what to do with the time that is given to us.",
    book: "The Fellowship of the Ring by J.R.R. Tolkien",
  },
  {
    quote:
      "Do I love you? My God, if your love were a grain of sand, mine would be a universe of beaches.",
    book: "The Princess Bride by William Goldman",
  },
  {
    quote: "There is nothing either good or bad, but thinking makes it so.",
    book: "Hamlet by William Shakespeare",
  },
  {
    quote: "I would always rather be happy than dignified.",
    book: "Jane Eyre by Charlotte Brontë",
  },
  {
    quote: "There are years that ask questions and years that answer.",
    book: "Their Eyes Were Watching God by Zora Neale Hurston",
  },
  {
    quote: "Pain demands to be felt.",
    book: "The Fault in Our Stars by John Green",
  },
  {
    quote:
      "It's the possibility of having a dream come true that makes life interesting.",
    book: "The Alchemist by Paulo Coelho",
  },
  {
    quote: "Whatever our souls are made of, his and mine are the same.",
    book: "Wuthering Heights by Emily Brontë",
  },
  {
    quote:
      "Isn't it nice to think that tomorrow is a new day with no mistakes in it yet?",
    book: "Anne of Green Gables by L.M. Montgomery",
  },
  {
    quote:
      "I wish I could show you, when you are lonely or in darkness, the astonishing light of your own being.",
    book: "Hafiz of Shiraz",
  },
  {
    quote:
      "It's so hard to forget pain, but it's even harder to remember sweetness. We have no scar to show for happiness. We learn so little from peace.",
    book: "Diary by Chuck Palahniuk",
  },
  {
    quote:
      "You can't stay in your corner of the Forest waiting for others to come to you. You have to go to them sometimes.",
    book: "Winnie-the-Pooh by A.A. Milne",
  },
  {
    quote:
      "The moment you doubt whether you can fly, you cease for ever to be able to do it.",
    book: "Peter Pan by J.M. Barrie",
  },
  {
    quote: "We are all in the gutter, but some of us are looking at the stars.",
    book: "Lady Windermere's Fan by Oscar Wilde",
  },
  {
    quote:
      "I can't go back to yesterday because I was a different person then.",
    book: "Alice's Adventures in Wonderland by Lewis Carroll",
  },
  {
    quote: "We loved with a love that was more than love.",
    book: "Annabel Lee by Edgar Allan Poe",
  },
  {
    quote: "Beware; for I am fearless, and therefore powerful.",
    book: "Frankenstein by Mary Shelley",
  },
  {
    quote:
      "I took a deep breath and listened to the old brag of my heart: I am, I am, I am.",
    book: "The Bell Jar by Sylvia Plath",
  },
  {
    quote: "The only limits for tomorrow are the doubts we have today.",
    book: "Pittacus Lore",
  },
  {
    quote: "Shoot for the moon, even if you miss, you'll land among the stars.",
    book: "Les Brown",
  },
  {
    quote:
      "Happiness can be found, even in the darkest of times, if one only remembers to turn on the light.",
    book: "Harry Potter and the Prisoner of Azkaban by J.K. Rowling",
  },
  {
    quote: "There is no greater agony than bearing an untold story inside you.",
    book: "I Know Why the Caged Bird Sings by Maya Angelou",
  },
  {
    quote:
      "A reader lives a thousand lives before he dies . . . The man who never reads lives only one.",
    book: "A Dance with Dragons by George R.R. Martin",
  },
  {
    quote:
      "The flower that blooms in adversity is the rarest and most beautiful of all.",
    book: "Mulan",
  },
  {
    quote: "You don’t have to live forever, you just have to live.",
    book: "Tuck Everlasting by Natalie Babbitt",
  },
  {
    quote: "And now that you don't have to be perfect, you can be good.",
    book: "East of Eden by John Steinbeck",
  },
  {
    quote: "To the well-organized mind, death is but the next great adventure.",
    book: "Harry Potter and the Sorcerer's Stone by J.K. Rowling",
  },
  {
    quote:
      "It is our choices, Harry, that show what we truly are, far more than our abilities.",
    book: "Harry Potter and the Chamber of Secrets by J.K. Rowling",
  },
  {
    quote:
      "The only thing necessary for the triumph of evil is for good men to do nothing.",
    book: "Edmund Burke",
  },
  {
    quote:
      "If we wait until we're ready, we'll be waiting for the rest of our lives.",
    book: "Lemony Snicket",
  },
  {
    quote: "The truth is rarely pure and never simple.",
    book: "The Importance of Being Earnest by Oscar Wilde",
  },
  {
    quote: "Most people are nice when you finally see them.",
    book: "To Kill a Mockingbird by Harper Lee",
  },
  {
    quote:
      "People generally see what they look for, and hear what they listen for.",
    book: "To Kill a Mockingbird by Harper Lee",
  },
  {
    quote:
      "You can never get a cup of tea large enough or a book long enough to suit me.",
    book: "C.S. Lewis",
  },
  {
    quote:
      "Sometimes you have to be apart from people you love, but that doesn't make you love them any less. Sometimes you love them more.",
    book: "The Last Song by Nicholas Sparks",
  },
  {
    quote:
      "It's no use going back to yesterday, because I was a different person then.",
    book: "Alice's Adventures in Wonderland by Lewis Carroll",
  },
  {
    quote:
      "Life appears to me too short to be spent in nursing animosity or registering wrongs.",
    book: "Jane Eyre by Charlotte Brontë",
  },
  {
    quote:
      "The world is full of magic things, patiently waiting for our senses to grow sharper.",
    book: "W.B. Yeats",
  },
  {
    quote: "Everything you can imagine is real.",
    book: "Pablo Picasso",
  },
  {
    quote: "What's done cannot be undone.",
    book: "Macbeth by William Shakespeare",
  },
  {
    quote: "Love all, trust a few, do wrong to none.",
    book: "All's Well That Ends Well by William Shakespeare",
  },
  {
    quote: "Time flies over us, but leaves its shadow behind.",
    book: "The Marble Faun by Nathaniel Hawthorne",
  },
  {
    quote:
      "My advice is, never do tomorrow what you can do today. Procrastination is the thief of time.",
    book: "David Copperfield by Charles Dickens",
  },
  {
    quote: "I am not young enough to know everything.",
    book: "The Critic as Artist by Oscar Wilde",
  },
  {
    quote: "Angry people are not always wise.",
    book: "Pride and Prejudice by Jane Austen",
  },
  {
    quote: "To define is to limit.",
    book: "The Picture of Dorian Gray by Oscar Wilde",
  },
  {
    quote:
      "For every minute you are angry you lose sixty seconds of happiness.",
    book: "Ralph Waldo Emerson",
  },
  {
    quote: "There is no charm equal to tenderness of heart.",
    book: "Emma by Jane Austen",
  },
  {
    quote: "The worst enemy to creativity is self-doubt.",
    book: "The Unabridged Journals of Sylvia Plath by Sylvia Plath",
  },
  {
    quote: "He who has a why to live can bear almost any how.",
    book: "Friedrich Nietzsche",
  },
  {
    quote:
      "He stepped down, trying not to look long at her, as if she were the sun, yet he saw her, like the sun, even without looking.",
    book: "Anna Karenina by Leo Tolstoy",
  },
  {
    quote:
      "If you look the right way, you can see that the whole world is a garden.",
    book: "The Secret Garden by Frances Hodgson Burnett",
  },
  {
    quote: "I have loved the stars too fondly to be fearful of the night.",
    book: "Galileo Galilei",
  },
  {
    quote: "Learning never exhausts the mind.",
    book: "Leonardo da Vinci",
  },
  {
    quote:
      "The more that you read, the more things you will know. The more that you learn, the more places you'll go.",
    book: "I Can Read With My Eyes Shut! by Dr. Seuss",
  },
  {
    quote: "It's kind of fun to do the impossible.",
    book: "Walt Disney",
  },
  {
    quote:
      "The only real prison is fear, and the only real freedom is freedom from fear.",
    book: "Aung San Suu Kyi",
  },
  {
    quote: "I like the night. Without the dark, we'd never see the stars.",
    book: "Twilight by Stephenie Meyer",
  },
  {
    quote: "Those who don't believe in magic will never find it.",
    book: "The Minpins by Roald Dahl",
  },
  {
    quote: "I have always imagined that Paradise will be a kind of library.",
    book: "Jorge Luis Borges",
  },
  {
    quote: "Don't cry because it's over, smile because it happened.",
    book: "Dr. Seuss",
  },
  {
    quote: "Life is what happens to us while we are making other plans.",
    book: "Allen Saunders",
  },
  {
    quote:
      "It is better to be hated for what you are than to be loved for what you are not.",
    book: "Autumn Leaves by André Gide",
  },
  {
    quote: "Forever is composed of nows.",
    book: "Emily Dickinson",
  },
  {
    quote: "You must be the change you wish to see in the world.",
    book: "Mahatma Gandhi",
  },
  {
    quote: "I am not what happened to me, I am what I choose to become.",
    book: "C.G. Jung",
  },
  {
    quote:
      "What lies behind us and what lies before us are tiny matters compared to what lies within us.",
    book: "Ralph Waldo Emerson",
  },
  {
    quote: "Even the darkest night will end and the sun will rise.",
    book: "Les Misérables by Victor Hugo",
  },
  {
    quote:
      "When you can't find someone to follow, you have to find a way to lead by example.",
    book: "Bad Feminist by Roxane Gay",
  },
  {
    quote:
      "In the depth of winter, I finally learned that within me there lay an invincible summer.",
    book: "Albert Camus",
  },
  {
    quote:
      "Never doubt that a small group of thoughtful, committed, citizens can change the world. Indeed, it is the only thing that ever has.",
    book: "Margaret Mead",
  },
  {
    quote: "You can't help everyone, but everyone can help someone.",
    book: "Ronald Reagan",
  },
  {
    quote: "Be yourself; everyone else is already taken.",
    book: "Oscar Wilde",
  },
  {
    quote: "To love at all is to be vulnerable.",
    book: "The Four Loves by C.S. Lewis",
  },
  {
    quote: "The only thing we have to fear is fear itself.",
    book: "Franklin D. Roosevelt",
  },
  {
    quote:
      "You can, you should, and if you’re brave enough to start, you will.",
    book: "Stephen King",
  },
  {
    quote: "Failure is the condiment that gives success its flavor.",
    book: "Truman Capote",
  },
  {
    quote:
      "Success is not final, failure is not fatal: It is the courage to continue that counts.",
    book: "Winston Churchill",
  },
  {
    quote: "So many books, so little time.",
    book: "Frank Zappa",
  },
  {
    quote:
      "Do not go where the path may lead, go instead where there is no path and leave a trail.",
    book: "Ralph Waldo Emerson",
  },
  {
    quote: "If you tell the truth, you don't have to remember anything.",
    book: "Mark Twain",
  },
  {
    quote:
      "Friendship is born at that moment when one person says to another: 'What! You too? I thought I was the only one.'",
    book: "C.S. Lewis",
  },
  {
    quote: "No act of kindness, no matter how small, is ever wasted.",
    book: "Aesop",
  },
  {
    quote:
      "The only limit to our realization of tomorrow will be our doubts of today.",
    book: "Franklin D. Roosevelt",
  },
];
