import { useState } from "react";
import CustomFloatingInput from "../Utilities/CustomFloatingInput";
import CustomFloatingSelect from "../Utilities/CustomFloatingSelect";
import { AddressRequest } from "../Serializers/SerializerModels";
import { saveAddress } from "./AddressUtils";
import Loading from "../Common/Loading";

const AddAddressComponent = ({ onClose, setAddress, address,toast }) => {
  const initialAddress = {
    name: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    pincode: "",
    state: "",
    country: "",
    phoneNumber: "",
  };

  const [editedAddress, setEditedAddress] = useState(initialAddress);
  const [isLoading, setIsLoading] = useState(false);


  const validateField = (name, value) => {
    switch (name) {
      case "name":
      case "addressLine1":
      case "city":
      case "state":
      case "country":
        if (!value.trim()) return false;
        break;
      case "pincode":
        if (!/^\d{5,6}$/.test(value)) return false;
        break;
      case "phoneNumber":
        if (!/^\d{10}$/.test(value)) return false;
        break;
      default:
        return true;
    }
    return true;
  };

  const handleInputChange = (e) => {
    setEditedAddress({ ...editedAddress, [e.target.id]: e.target.value });
  };
  const handleSelectChange = (e) => {
    setEditedAddress({ ...editedAddress, [e.target.id]: e.target.value });
  };
  const handleSave = () => {
    saveAddress(new AddressRequest(editedAddress), setIsLoading, toast);
    onClose();
  };
  const allValid = () => {
    let isValid = true;
    for (const [key, value] of Object.entries(editedAddress)) {
      if (!validateField(key, value)) {
        isValid = false;
        break;
      }
    }
    return isValid;
  };

  return (
    <div className="max-w-lg w-full bg-white rounded-xl shadow-xl  transition duration-300 ease-in-out hover:shadow-xl p-4 h-full">
      {isLoading ? (
        <>
          <Loading />
        </>
      ) : (
        <>
          <div className="rounded-lg flex flex-col  gap-4">
            <div className="text-xl font-semibold text-start">
              Address Detail
            </div>
            <CustomFloatingInput
              value={editedAddress.name}
              type={"text"}
              text={"Name"}
              id={"name"}
              onChange={(e) => handleInputChange(e)}
              required={true}
              isValid={validateField("name", editedAddress.name)}
            />
            <CustomFloatingInput
              value={editedAddress.addressLine1}
              type={"text"}
              text={"Address Line 1"}
              id={"addressLine1"}
              onChange={(e) => handleInputChange(e)}
              required={true}
              isValid={validateField(
                "addressLine1",
                editedAddress.addressLine1
              )}
            />
            <CustomFloatingInput
              value={editedAddress.addressLine2}
              type={"text"}
              text={"Address Line 2"}
              id={"addressLine2"}
              isValid={validateField(
                "addressLine2",
                editedAddress.addressLine2
              )}
              onChange={(e) => handleInputChange(e)}
            />
            <CustomFloatingSelect
              values={[
                { label: "", value: "" },
                { label: "Pune", value: "Pune" },
              ]}
              label={"City"}
              id={"city"}
              onChange={(e) => handleSelectChange(e)}
              isValid={validateField("city", editedAddress.city)}
            />
            <CustomFloatingSelect
              values={[
                { label: "", value: "" },
                { label: "Maharashtra", value: "Maharashtra" },
              ]}
              label={"State"}
              id={"state"}
              onChange={(e) => handleSelectChange(e)}
              isValid={validateField("state", editedAddress.state)}
            />
            <CustomFloatingInput
              value={editedAddress.pincode}
              type={"text"}
              text={"Pincode"}
              id={"pincode"}
              onChange={(e) => handleInputChange(e)}
              isValid={validateField("pincode", editedAddress.pincode)}
            />
            <CustomFloatingSelect
              values={[
                { label: "", value: "" },
                { label: "India", value: "India" },
              ]}
              label={"Country"}
              id={"country"}
              onChange={(e) => handleSelectChange(e)}
              isValid={validateField("country", editedAddress.country)}
            />
            <CustomFloatingInput
              type={"number"}
              text={"Phone Number"}
              id={"phoneNumber"}
              onChange={(e) => handleInputChange(e)}
              isValid={validateField("phoneNumber", editedAddress.phoneNumber)}
            />
            <div className="flex justify-between">
              <button
                className={`mt-4 px-4 py-2 rounded text-white  bg-red-500 hover:bg-red-600 
              `}
                onClick={onClose}
              >
                Close
              </button>
              <button
                className={`mt-4 px-4 py-2 rounded text-white  ${
                  allValid() ? `bg-blue-500 hover:bg-blue-600 ` : `bg-gray-300 `
                }`}
                disabled={!allValid()}
                onClick={handleSave}
              >
                Save
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AddAddressComponent;
