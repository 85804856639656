import queryString from "query-string";
import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Loading from "../Common/Loading";
import { ssoVerify } from "./LoginUtils";
import { UserContext } from "../../App";

const SsoVerification = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const { setIsUserLoggedIn } = useContext(UserContext);
  const token = queryParams.token || "";
  const error = queryParams.error || "";

  useEffect(() => {
    if (token) {
      ssoVerify({ token, navigate, setIsUserLoggedIn });
    }
  }, [token, navigate]);

  return (
    <div className="flex items-center justify-center h-full w-full bg-gray-100">
      {error ? (
        <>

          <div className="max-w-md w-full bg-white shadow-lg rounded-lg overflow-hidden">
            <div className="p-6">
              <h2 className="text-3xl font-bold mb-4 text-center">Oops!</h2>
              <p className="text-lg mb-4  text-gray-700">
                It seems you are not a user of Readkar or were not invited.
              </p>
              <p className="text-lg mb-4  text-gray-700">
                If invited, please <a href="/signup" className="text-blue-500" > Sign up</a>.
              </p>
              <p className="text-lg mb-4  text-gray-700">
                If not, don't worry, join the waitlist to get your invitation
                code.
              </p>
              <a href="/waitlist" className="block text-center">
                <button className="bg-blue-500 text-white  hover:bg-blue-700  font-bold py-2 px-4 rounded">
                  Join Now
                </button>
              </a>
            </div>
          </div>
          
        </>
      ) : (
        <Loading />
      )}
      
    </div>
  );
};

export default SsoVerification;
