import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import WaitlistModal from "../Waitlist/WaitlistModal";
import Card3 from "../LandingPage/Card3";
import { useParams } from "react-router-dom";
import BlogCards from "./BlogCards";
import BlogDetails from "./BlogDetails";
import { fetchBlogByTitleId, fetchBlogs } from "./BlogsUtils";
import Loading from "../Common/Loading";

const BlogPageComponent = () => {
  const [showWaitListModal, setShowWaitListModal] = useState(false);

  const { titleId } = useParams();
  const [blogData, setBlogData] = useState([]);
  const [blogPageData, setBlogPageData] = useState(null);
  const [loading, setLoading] = useState(true);
  const limit = titleId ? 3 : 10;

  useEffect(() => {
    fetchBlogs(setBlogData, setLoading,limit);
  }, []);
  useEffect(() => {
    console.log(titleId);
    if(!titleId){
      return;
    }
    fetchBlogByTitleId(titleId, setBlogPageData);
  }, [titleId]);

  return (
    <div className="flex flex-col bg-customYellow">
      <Helmet>
        <title>
          {titleId   ? String(blogPageData?.title) : "Readkar | Blogs"}
        </title>
        <meta
          name="description"
          content="Explore our blogs to learn how to improve your coffee-making skills and enhance your blogging experience."
        />
      </Helmet>

      <div className="rounded bg-white rounded-t-2xl">
        {loading ? (
          <div className="h-screen ">
            {" "}
            <Loading />
          </div>
        ) : (
          <>
            <div className=" mx-auto py-8 ">
              <WaitlistModal
                show={showWaitListModal}
                setShowWaitListModal={setShowWaitListModal}
              />
              {titleId ? (
                <BlogDetails
                  titleId={titleId}
                  blogCardData={blogData}
                  blogData={blogPageData}
                />
              ) : (
                <BlogCards blogData={blogData} />
              )}
              <Card3 setShowWaitListModal={setShowWaitListModal} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default BlogPageComponent;
