export const bookFacts = [
  "The longest novel ever written is 'In Search of Lost Time' by Marcel Proust.",
  "The first book ever printed is the 'Gutenberg Bible' around 1455.",
  "The best-selling book series of all time is 'Harry Potter' by J.K. Rowling.",
  "The most expensive book ever purchased was Leonardo da Vinci’s 'Codex Leicester', bought by Bill Gates.",
  "The first major book written on a typewriter was 'The Adventures of Tom Sawyer'.",
  "The largest book in the world measures 5 meters x 8.06 meters.",
  "Books were chained to the shelves in some medieval libraries.",
  "The most stolen books in libraries and bookstores are the Bible and books by Charles Bukowski.",
  "The practice of collecting books is known as bibliophilia.",
  "A 'bookworm' refers to any insect that eats the paper or binding of books.",
  "The smell of old books comes from volatile organic compounds breaking down.",
  "The longest book title contains over 1,000 characters.",
  "The first novel ever written on a typewriter is believed to be 'Life on the Mississippi' by Mark Twain.",
  "The oldest known cookbook in existence dates back to 1700 BCE.",
  "E-books sales surpassed printed book sales in the early 2010s but have stabilized since.",
  "The smallest book in the world measures 0.07 mm x 0.10 mm.",
  "Isaac Newton's 'Principia Mathematica' is one of the most influential books in history.",
  "The most translated author is Agatha Christie.",
  "The most translated book, excluding religious works, is 'The Little Prince' by Antoine de Saint-Exupéry.",
  "J.R.R. Tolkien’s 'The Hobbit' was originally conceived as a children’s book.",
  "‘Bibliosmia’ is the enjoyment of smelling good old books.",
  "The first known mention of the word 'book' in English is over 1,000 years old.",
  "The Codex Gigas is the largest medieval book, also known as the Devil’s Bible.",
  "The longest sentence in literature is found in 'Absalom, Absalom!' by William Faulkner.",
  "A 'tome' typically refers to a large and scholarly book.",
  "The record for most people balancing books on their heads at the same place and time is 998.",
  "A book that is no longer in print is referred to as 'out of print'.",
  "Many libraries and bookstores have resident cats.",
  "The first recorded recipe dates back to Ancient Sumer.",
  "Book collecting can be a form of art collecting, depending on the editions and the bindings.",
  "The Bay Psalm Book is the first book printed in what is now the United States.",
  "Penguin Books was a pioneer in paperback publishing in the 1930s.",
  "The World's biggest book fair is the Frankfurt Book Fair.",
  "A book originally written in a digital format is called an 'e-book'.",
  "The term 'blurb' was coined in 1907.",
  "A 'chapbook' is a small publication, typically of poetry.",
  "The first mobile library was a horse-drawn cart in the UK.",
  "Japan’s 'Picture Book Library' is designed specifically for children.",
  "The first known use of the word 'bookstore' dates back to 1761.",
  "Margaret Atwood invented a remote robotic writing technology to sign books from afar.",
  "The largest library in the world is the Library of Congress, USA.",
  "The first science fiction novel is generally considered to be 'Frankenstein' by Mary Shelley.",
  "The book with the most authors is 'Business Ethics' with 150 authors.",
  "The average number of words per printed book page is between 250 and 300.",
  "The first book to ever be adapted into a movie was 'Alice in Wonderland' in 1903.",
  "Books were sold in vending machines in the 19th century.",
  "A 'fore-edge painting' is a scene painted on the edges of the pages of a book.",
  "A book’s spine was first added in the early 16th century to protect the pages.",
  "The most popular book in prisons is the dictionary.",
  "The 'Book of Kells' is one of the most famous illuminated manuscripts.",
  "Comic books are not necessarily humorous; the term refers to their format.",
  "The World Record for the most people reading aloud from the same document is 223,363 participants.",
  "The largest book collection private owned was 1.6 million books.",
  "The phrase 'to throw the book at someone' means to punish as severely as possible.",
  "A 'book doctor' does not heal people but repairs damaged books.",
  "Stephen King’s first novel 'Carrie' was nearly thrown away before his wife saved it.",
  "Banned Books Week promotes awareness of challenges to library materials.",
  "The first autobiography ever written is considered to be 'The Confessions' by Augustine of Hippo.",
  "A 'manuscript' refers to any document written by hand.",
  "A 'bibliotaph' is a person who hoards books.",
  "Leather binding for books was first introduced in the 16th century.",
  "The 'Voynich Manuscript' is a book written in an unknown language with no known author.",
  "Books can be made from various materials, traditionally from paper derived from wood or cloth.",
  "A 'rare book' is any book that is hard to find due to its age, limited issue, or specific interest.",
  "In Fahrenheit 451, books are illegal and 'firemen' burn any that are found.",
  "‘Anthology’ is a collection of literary works chosen by the compiler.",
  "The first public library opened in 1833 in Peterborough, New Hampshire, USA.",
  "A 'Limited Edition' book is published in a small number of copies.",
  "Hypermodern books are collected for being examples of contemporary book design.",
  "A 'bookplate' is a small print or decorative label pasted into a book to indicate ownership.",
  "The largest academic library in the world is Harvard University’s Library.",
  "A 'book town' is a town with a high number of second-hand or antiquarian bookshops.",
  "The most commonly misquoted book is the Bible.",
  "The Papyrus Ebers is one of the oldest medical documents known, dated around 1550 BCE.",
  "A book made in the shape of a circle is known as a 'round book'.",
  "The longest audiobook recorded is 'War and Peace' by Leo Tolstoy.",
  "A 'bestseller' is a book that is included on a list of top-selling or frequently borrowed titles.",
  "The 'L-Space' theory suggests that all libraries are interconnected in the 'Discworld' series by Terry Pratchett.",
  "The process of hand-making books is called bookbinding.",
  "‘Marginalia’ are marks made in the margins of a book by a reader.",
  "A 'ghostwriter' is a person who writes a book or article for someone else who is named as the author.",
  "The concept of copyright was first introduced to protect the rights of book authors and publishers.",
  "The original bookmobile was created in the early 20th century to extend library services to rural areas.",
  "The 'International Standard Book Number' (ISBN) uniquely identifies books and book-like products.",
  "A 'palimpsest' is a manuscript page that has been written on, scraped off, and used again.",
  "The first detective story is often considered 'The Murders in the Rue Morgue' by Edgar Allan Poe.",
  "Books that teach you how to do something are called 'how-to' books.",
  "A 'colophon' at the end of a book provides information about its production.",
  "An 'epistolary novel' is a novel written as a series of documents, such as letters or emails.",
  "The largest book theft from a single library is estimated at 200,000 books stolen from the Girolamini Library in Italy.",
  "A 'book launch' is an event to celebrate the publication of a new book.",
];
