import CustomFloatingInput from "../Utilities/CustomFloatingInput";
import InstaLogo from "../../Assets/Instagram_Glyph_Black.png";
import { WaitlistModelRequest } from "../Serializers/SerializerModels";

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(String(email).toLowerCase());
};

export const validatePhone = (phone) => {
  const re = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

  return re.test(String(phone).toLowerCase());
};

export const validateFields = (value) => {
  return value.length > 0;
};

export const Page1 = ({ formData, handleChange, nextStep }) => (
  <div className="space-y-4 mt-4">
    <CustomFloatingInput
      type="text"
      text="First Name"
      id="firstName"
      required
      value={formData.firstName}
      onChange={handleChange("firstName")}
    />
    <CustomFloatingInput
      type="text"
      text="Last Name"
      id="lastName"
      required
      value={formData.lastName}
      onChange={handleChange("lastName")}
    />
    <CustomFloatingInput
      type="email"
      text="Email Address"
      id="email"
      required
      value={formData.email}
      onChange={handleChange("email")}
    />
    <CustomFloatingInput
      type="text"
      text="Phone Number"
      id="phone"
      required
      value={formData.phone}
      onChange={handleChange("phone")}
    />
    
    <div className="flex justify-end">
      <button
        className="bg-gray-600 text-white  hover:bg-gray-800 font-bold py-2 px-4 rounded transition duration-300 ease-in-out "
        type="button"
        onClick={nextStep}
      >
        Submit
      </button>
    </div>
  </div>
);

export const Page2 = ({
  formData,
  handleChange,
  nextStep,
  prevStep,
  isLoading,
}) => (
  <div className="space-y-4">
    <CustomFloatingInput
      type="number"
      text="How many Books have you read till date?"
      id="booksRead"
      required={true}
      value={formData.booksRead}
      onChange={handleChange("booksRead")}
    />
    <CustomFloatingInput
      type="text"
      text="What is your favourite book?"
      id="favouriteBook"
      required
      value={formData.favouriteBook}
      onChange={handleChange("favouriteBook")}
    />
    <CustomFloatingInput
      type="text"
      text="What book do you plan to read next?"
      id="bookNextRead"
      required
      value={formData.bookNextRead}
      onChange={handleChange("bookNextRead")}
    />
    <CustomFloatingInput
      type="number"
      text="How many books do you read monthly?"
      id="booksPerMonth"
      required
      value={formData.booksPerMonth}
      onChange={handleChange("booksPerMonth")}
    />
    <div className="flex justify-between">
      <button
        className="bg-gray-600 text-white  hover:bg-gray-800 font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
        type="button"
        onClick={prevStep}
      >
        Back
      </button>
      <button
        className={`bg-gray-600 text-white  hover:bg-gray-800  font-bold py-2 px-4 rounded transition duration-300 ease-in-out ${
          isLoading ? "opacity-50 cursor-not-allowed" : ""
        }`}
        type="button"
        onClick={nextStep}
        disabled={isLoading}
      >
        {isLoading ? "Submitting..." : "Submit"}
      </button>
    </div>
  </div>
);

export const confirmationModal = ({ email, firstName, lastName }) => {
  return (
    <>
      <div className="max-w-lg w-full bg-white rounded-lg shadow-lg p-6 mb-4">
        <div className="flex flex-col items-center">
          <h2 className="text-2xl font-bold mb-4">
            Thank you for joining our waitlist!
          </h2>
          <h2 className="text-2xl from-neutral-600 mb-4">
            Hey {firstName} {lastName},
          </h2>

          <p className="text-gray-700 mb-4">
            We will reach out to you shortly on <b>{email}</b> with your
            invitation code. Keep an eye on your inbox.
          </p>
          <p className="text-gray-700 font-semibold text-md mb-4">
            Happy Reading!!!
          </p>
          <p className="text-gray-600 mb-4">
            Also follow us on social for more updates.
          </p>
          <div className="flex items-center w-2/3 justify-center">
            <a
              href="https://www.instagram.com/readkar_com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={InstaLogo} alt="Instagram" className=" w-6 " />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export const errorModal = ({ email, firstName, lastName, error }) => {
  return (
    <>
      <div className="max-w-lg w-full bg-white rounded-lg shadow-lg p-6 mb-4">
        <div className="flex flex-col items-center">
          <h2 className="text-2xl font-bold mb-4">
            {error?.status === 409 ? "Already in waitlist!" : "Error"}
          </h2>
          <h2 className="text-2xl text-neutral-600 mb-4">
            {error?.status === 409 ? `Hey ${firstName} ${lastName},` : ""}
          </h2>
          <p className="text-gray-700 mb-4">
            {error?.status === 409
              ? `You have already joined our waitlist. We will reach out to you shortly on ${(
                  <b> {`${email}`}</b>
                )} with your invitation code. Keep an eye on your inbox.`
              : "An error occurred. Please try again later."}
          </p>
          {error?.status === 409 && (
            <>
              <p className="text-gray-700 font-semibold text-md mb-4">
                Happy Reading!!!
              </p>
              <p className="text-gray-600 mb-4">
                Also follow us on social for more updates.
              </p>
              <div className="flex items-center w-2/3 justify-center">
                <a
                  href="https://www.instagram.com/readkar_com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={InstaLogo} alt="Instagram" className="w-6" />
                </a>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export const handleSubmit = async (
  formData,
  setShowModal,
  setShowErrorModal,
  setFormData,
  setError,
  toast,
  setIsLoading,
  setShowWaitListModal
) => {
  try {
    setIsLoading(true);
    const requestBody = new WaitlistModelRequest(formData);
    const response = await fetch(`https://api-prod.readkar.com/waitlist/join`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });
    if (response.ok) {
      const data = await response.json();
      toast.success("Thank you for joining our waitlist! We will reach out to you shortly on your email.");
      setShowModal(true);
    } else {
      const error = await response.json();
      console.error(error, "error in errro");

      if (response.status === 409) {
        setError({ status: response.status, message: error.message });
        setShowErrorModal(true);
      } else {
        toast.error("An error occurred. Please try again.");
        setFormData({
          // Reset form data
          email: "",
          phone: "",
          firstName: "",
          lastName: "",
          booksRead: "",
          favouriteBook: "",
          bookNextRead: "",
          booksPerMonth: "",
        });
      }
    }
  } catch (err) {
    // console.error(err, "error in errro");
    toast.error("Network error. Please try again later.");
    setFormData({
      // Reset form data on network error too
      email: "",
      phone: "",
      firstName: "",
      lastName: "",
      booksRead: "",
      favouriteBook: "",
      bookNextRead: "",
      booksPerMonth: "",
    });
  } finally {
    setIsLoading(false);

  }
};
