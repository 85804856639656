import { BookModel, SearchRequest } from "../Serializers/SerializerModels";
import { fetchPostData } from "../Utilities/FetchUtils";

export const handleSearch = async ({
  searchTerm,
  setBooksDetails,
  setIsLoading,
  setError,
}) => {
  setIsLoading(true);
  setError(null);
  const { response, _ } = await fetchPostData(
    "books/search",
    new SearchRequest(searchTerm,100)
  );

  if (response) {
    const bookdetails = response.book_details.map(
      (book) => new BookModel(book)
    );
    if (bookdetails.length === 0) {
      setError(`No books found for ${searchTerm}`);
    }
    setBooksDetails(bookdetails);
  } else {
    setError(`No books found for ${searchTerm}`);
  }
  setIsLoading(false);
  // if (response.ok) {
  //   setSearched(true);
  //   if (data.results.book_details) {
  //     const books = data.results.book_details.map(
  //       (book) => new BookModel(book)
  //     );
  //     setBooksDetails(books);
  //   } else {
  //     setBooksDetails([]);
  //   }
  // }
};
