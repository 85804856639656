import React, { useEffect, useState } from "react";
import CustomFloatingInput from "../Utilities/CustomFloatingInput";
import { GoogleButton } from "../Utilities/GoogleSSOButtons";
import sideImage from "../../Assets/illustration.svg";
import { handleSignup, isValidEmail, isValidPassword } from "./SignupUtilities";
import { DividerWithText, showToast } from "../Common/UtilsComponent";

import { SignupRequest } from "../Serializers/SerializerModels";
import VerifyInvite from "./VerifyInvite";
import Loading from "../Common/Loading";
import { useLocation, useNavigate } from "react-router-dom";
import SuccessConfirmation from "./SuccessConfirimation";
import { verifyLogin } from "./AuthUtils";
import queryString from "query-string";
import { fetchGetDataNoAuth } from "../Utilities/FetchUtils";
function Signup() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState(true);
  const [isTokenVerified, setIsTokenVerified] = useState(false);
  const [tokenErrorMessage, setTokenErrorMessage] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  const invite_code = queryParams.invite_code || "";

  const [inviteCode, setInviteCode] = useState(invite_code);

  useEffect(() => {
    const userLoggedIn = async () => {
      try {
        setIsLoading(true);
        const loggedIn = await verifyLogin();
        if (loggedIn) {
          navigate("/home");
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };
    userLoggedIn();
    if (inviteCode) {
      verifyInviteCode();
    }
  }, []);
  const verifyInviteCode = async () => {
    setIsLoading(true);
    try {
      if (inviteCode) {
        const { response, error } = await fetchGetDataNoAuth(
          `user/verify_invite?code=${inviteCode}`
        );
        if (response) {
          setIsTokenVerified(true);
        } else {
          setTokenErrorMessage("Not A Valid Invite Code!");
        }
      }
    } catch (error) {
      setTokenErrorMessage("Not A Valid Invite Code!");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isPasswordValid) {
      setErrorMessage("");
    } else {
      setErrorMessage(`Password must be at least 8 characters., 
                       Must have an uppercase letter., 
                       Must have a lowercase letter., 
                       Must have a number., 
                       Must have a special character.`);
    }
  }, [isPasswordValid]);
  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setIsPasswordValid(isValidPassword(newPassword));

    if (newPassword === confirmPassword) {
      setIsConfirmPasswordValid(true);
    }
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setIsEmailValid(isValidEmail(newEmail));
  };
  const handleConfirmPasswordChange = (e) => {
    const newPassword = e.target.value;
    setConfirmPassword(newPassword);
    setIsConfirmPasswordValid(password === newPassword);
  };
  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isEmailValid && isPasswordValid && isConfirmPasswordValid) {
      const signupRequest = new SignupRequest({
        email,
        password,
        firstName,
        lastName,
        inviteCode,
      });

      handleSignup(signupRequest, setIsLoading, setShowSuccessModal);
    }
  };

  return (
    <div className="flex flex-col items-center h-[100%] bg-gray-100 justify-center">
      {showSuccessModal ? (
        <SuccessConfirmation email={email} />
      ) : (
        <>
          {!isTokenVerified ? (
            <>
              {isLoading ? (
                <>
                  <Loading />
                </>
              ) : (
                <VerifyInvite
                  setIsTokenVerified={setIsTokenVerified}
                  inviteCode={inviteCode}
                  setInviteCode={setInviteCode}
                  tokenErrorMessage={tokenErrorMessage}
                  setTokenErrorMessage={setTokenErrorMessage}
                />
              )}
            </>
          ) : (
            <>
              {isLoading ? (
                <>
                  <Loading />
                </>
              ) : (
                <div className="flex flex-row items-center">
                  <div className="w-full max-w-xs px-6 py-8 bg-white overflow-hidden sm:max-w-sm rounded-lg shadow-lg border-1">
                    <p className="text-start text-4xl font-bold mb-6">
                      Sign Up
                    </p>
                    <form onSubmit={handleSubmit}>
                      <div className="mb-4 flex flex-row gap-2 ">
                        <CustomFloatingInput
                          type="text"
                          id="first_name"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          text="First Name"
                          icon="fa-solid fa-user"
                        />
                        <CustomFloatingInput
                          type="text"
                          id="last_name"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                          text="Last Name"
                          icon="fa-solid fa-user"
                        />
                      </div>

                      <div className="mb-4">
                        <CustomFloatingInput
                          type="email"
                          id="email"
                          value={email}
                          onChange={handleEmailChange}
                          text="Email"
                          icon="fa-solid fa-envelope"
                          isValid={isEmailValid}
                        />
                      </div>
                      <div className="mb-6">
                        <CustomFloatingInput
                          type={passwordShown ? "text" : "password"}
                          id="password"
                          value={password}
                          onChange={handlePasswordChange}
                          text="Password"
                          icon="fa-solid fa-lock"
                          isValid={isPasswordValid}
                          togglePassword={togglePasswordVisibility}
                        />
                      </div>
                      <div className="mb-6">
                        <CustomFloatingInput
                          type={"password"}
                          id="confirm_password"
                          value={confirmPassword}
                          onChange={handleConfirmPasswordChange}
                          text="Confirm Password"
                          icon="fa-solid fa-lock"
                          isValid={isConfirmPasswordValid}
                        />
                      </div>
                      {errorMessage && (
                        <div className="mb-4 text-sm text-red-500">
                          <ul className="list-disc pl-5">
                            {errorMessage.split(",").map((msg, index) => (
                              <li key={index}>{msg}</li>
                            ))}
                          </ul>
                        </div>
                      )}
                      <div className="flex items-center justify-between">
                        <button
                          type="submit"
                          className="  w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          disabled={
                            !isEmailValid ||
                            !isPasswordValid ||
                            !isConfirmPasswordValid
                          }
                        >
                          Sign Up
                        </button>
                      </div>
                    </form>
                    <DividerWithText text="OR" />
                    <div className="flex flex-col md:flex-row  mb-4 justify-center mt-4">
                      <div className="md:m-2 sm:m-1 w-full">
                        <GoogleButton inviteCode={inviteCode} />
                      </div>
                    </div>
                    <div className="mt-4 text-center">
                      <p className="text-sm">
                        Already have an account?{" "}
                        <a
                          href="/login"
                          className="font-medium text-blue-600 hover:text-blue-500"
                        >
                          Sign In here
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="hidden md:block md:w-1/2">
                    <img
                      src={sideImage}
                      alt="Descriptive Alt Text"
                      className="object-cover h-full"
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default Signup;
