import { useEffect, useState } from "react";
import CustomFloatingInput from "../Utilities/CustomFloatingInput";
import { getChats, getMessages, handleDeleteChat, handleSendMessage } from "./ChatUtils";
import Loading from "../Common/Loading";
import ChatTutorial from "./ChatTutorial";


const ChatComponent = () => {
    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState("");
    const [selectedShow, setSelectedShow] = useState("");
    const [selectedCharacter, setSelectedCharacter] = useState("");
    const [characterInput, setCharacterInput] = useState("");
    const [showInput, setShowInput] = useState("");
    const [isCharacterSelected, setIsCharacterSelected] = useState(false);
    const [chats, setChats] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isMessagesLoading, setIsMessagesLoading] = useState(false);

    const handleKeyPress = (e) => {
        if (e.key === "Enter") handleSendMessage(
            inputMessage,
            setInputMessage,
            setMessages,
            selectedShow,
            selectedCharacter,
            setIsMessagesLoading
        );
    };
    const handleSend = () => {
        handleSendMessage(
            inputMessage,
            setInputMessage,
            setMessages,
            selectedShow,
            selectedCharacter,
            setIsMessagesLoading
        );
    };

    useEffect(() => {
        getChats(setChats, setIsLoading);
    }, [])
    const handleCharacterSelect = (show, character) => {
        setSelectedShow(show.replace(/ /g, '-'));
        setSelectedCharacter(character.replace(/ /g, '-'));
        setIsCharacterSelected(true);
        getMessages(show + '_' + character, setMessages);
    };

    const handleNewChat = () => {
        if (characterInput === "" || showInput === "") return
        setSelectedCharacter(characterInput.trim().replace(/ /g, '-'));
        setSelectedShow(showInput.trim().replace(/ /g, '-'));
        setIsCharacterSelected(true);
        setMessages([]);
    }
    return (
        <div className="flex flex-col h-full w-full bg-gray-100 md:rounded-tl-2xl">
            {
                isLoading ? (
                    <Loading />
                ) :
                    <>
                        {!isCharacterSelected ? (

                            <>
                                <ChatSelect
                                    chats={chats}
                                    characterInput={characterInput}
                                    showInput={showInput}
                                    setCharacterInput={setCharacterInput}
                                    setShowInput={setShowInput}
                                    handleNewChat={handleNewChat}
                                    handleCharacterSelect={handleCharacterSelect}
                                    setChats={setChats}
                                    setIsLoading={setIsLoading}
                                />
                            </>
                        ) : (
                            <>
                                <ChatBox
                                    messages={messages}
                                    selectedCharacter={selectedCharacter}
                                    selectedShow={selectedShow}
                                    setInputMessage={setInputMessage}
                                    inputMessage={inputMessage}
                                    handleSend={handleSend}
                                    setIsCharacterSelected={setIsCharacterSelected}
                                    handleKeyPress={handleKeyPress}
                                    isLoading={isLoading}
                                    isMessagesLoading={isMessagesLoading}
                                />
                            </>
                        )}</>
            }


        </div>
    );
};

export default ChatComponent;




const ChatSelect = (
    { chats,
        characterInput,
        showInput,
        setCharacterInput,
        setShowInput,
        handleNewChat,
        handleCharacterSelect,
        setChats,
        setIsLoading
    }
) => {
    const [showTutorial, setShowTutorial] = useState(chats.length===0);

    return (
        <div className="flex flex-col items-center p-4">
            {
                chats.length === 0 && <>
                
                    <ChatTutorial  showTutorial={showTutorial} setShowTutorial={setShowTutorial}/>
                </>
            }
            <div className="flex flex-col md:flex-row items-center  w-full mb-4 m-4 border p-4  rounded-lg shadow-xl  bg-white gap-2 "  >

                <CustomFloatingInput
                    value={characterInput}
                    type={"text"}
                    text={"Character Name"}
                    id={"character_name"}
                    onChange={(e) => setCharacterInput(e.target.value)}
                    required={true}
                />
                <CustomFloatingInput
                    value={showInput}
                    type={"text"}
                    text={"Show / Book"}
                    id={"show_name"}
                    onChange={(e) => setShowInput(e.target.value)}
                    required={true}
                />
                <div className={` border  p-2 font-semibold rounded-lg ${showInput === "" || characterInput === "" ? "bg-gray-400 text-white" : `bg-blue-500 text-white`}`} onClick={() => handleNewChat()}>
                    Start  Chat
                </div>
            </div>
            {
                chats.map((chat) => (
                    <div className="flex   w-full mb-4 m-4 border p-2 rounded-lg shadow-xl bg-slate-300" onClick={() => handleCharacterSelect(chat.source, chat.character)}>
                        <div className="flex items-center">
                            <div className="w-14 h-14 border-2 bg-white  border-blue-800 rounded-full"></div>
                            <div className="ml-4 text-lg font-bold">
                                {`${chat.source.replace(/-/g, " ")} - ${chat.character.replace(/-/g, " ")}`}
                            </div>
                        </div>
                        <div className=" flex  flex-grow items-center justify-end me-4  ">
                            <i className="fa fa-trash ml-4 fa-lg cursor-pointer text-gray-500 hover:text-gray-900" onClick={(event) => {
                                event.stopPropagation();
                                handleDeleteChat(chat, setIsLoading)
                                getChats(setChats, setIsLoading)
                            }} />
                        </div>

                    </div>
                ))}
        </div>
    )
}


const ChatBox = ({
    messages,
    selectedCharacter,
    selectedShow,
    setInputMessage,
    inputMessage,
    handleSend,
    setIsCharacterSelected,
    handleKeyPress,
    isMessagesLoading
}) => {
    return (
        <>
            <div className="flex items-center px-4 py-2 bg-white shadow-md">
                <i className="fa fa-arrow-left mr-4 fa-lg cursor-pointer" onClick={() => setIsCharacterSelected(false)} />
                <div className="flex items-center justify-center w-full">
                    <div className="w-14 h-14 border-2 rounded-full"></div>
                    <div className="ml-4 text-lg font-bold">{`${selectedCharacter.replace(/-/g, " ")} - ${selectedShow.replace(/-/g, " ")}`}</div>
                </div>
            </div>
            <div className="flex flex-grow flex-col w-full h-full overflow-auto">
                <div className="flex  flex-grow overflow-auto">
                    <div className="flex flex-col w-full overflow-y-auto p-4 border-t border-b bg-white">
                        {messages.map((message, index) => (
                            <div
                                key={index}
                                className={`p-4 shadow-lg my-2 max-w-[60%] rounded-lg ${message.role === "user" ? "bg-blue-500 text-white self-end" : "bg-gray-300 text-black self-start "}`}
                            >
                                {message.content}
                            </div>
                        ))}
                        {isMessagesLoading && (
                            <div className="p-4 shadow-lg my-2 max-w-[60%] flex flex-row gap-2 rounded-lg  bg-gray-300 text-black self-start ">
                                <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce"></div>
                                <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce delay-200"></div>
                                <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce delay-400"></div>
                            </div>
                        )}
                    </div>
                </div>

                <div className="flex items-center p-4 bg-white shadow-md">
                    <input
                        type="text"
                        className="flex-grow px-4 py-2 border rounded-lg mr-4"
                        placeholder="Type your message..."
                        value={inputMessage}
                        onChange={(e) => setInputMessage(e.target.value)}
                        onKeyPress={handleKeyPress}
                    />
                    <i className="fa fa-paper-plane fa-lg cursor-pointer" onClick={handleSend} />
                </div>
            </div>
        </>
    );
}