import React, { useState, useEffect } from "react";
import { fetchDeliveries, updateStatus } from "./DeliveryComponentUtils";
import Loading from "../Common/Loading";
import { ToastContainer, toast } from "react-toastify";
import { CancelOrderRequest } from "../Serializers/SerializerModels";

const DeliveryComponent = ({ setSelectedTab }) => {
  const [currentDeliveries, setCurrentDeliveries] = useState([]); // Deliveries for the current status
  const [status, setStatus] = useState("TO_BE_DELIVERED");
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [update, setUpdate] = useState(false);

  const statuses = [
    {
      id: "TO_BUY_AND_DELIVER",
      label: "To Buy and Deliver",
      nextStatus: "DISPATCHED",
    },
    {
      id: "TO_BE_DELIVERED",
      label: "To Be Delivered",
      nextStatus: "DISPATCHED",
    },
    { id: "DISPATCHED", label: "Dispatched", nextStatus: "DELIVERED" },
    { id: "DELIVERED", label: "Delivered" },
    {
      id: "TO_BE_PICKED_UP",
      label: "To Be Picked Up",
      nextStatus: "PICKED_UP",
    },
    { id: "PICKED_UP", label: "Picked Up", nextStatus: "RETURNED" },
    { id: "RETURNED", label: "Returned" }, // Added to align with UbmStatus enum
    { id: "CANCELLED", label: "Cancelled" }, // Added to align with UbmStatus enum
  ];
  useEffect(() => {
    setSelectedTab("delivery");
  }, []);

  useEffect(() => {
    fetchDeliveries({
      status,
      offset,
      limit,
      setCurrentDeliveries,
      setLoading,
      setError,
    });
  }, [status, offset, limit, update]);

  const handleStatusChange = (newStatus) => {
    setStatus(newStatus);
    setOffset(0); // Reset pagination when switching status
  };

  const handleStatusUpdate = (delivery) => {
    updateStatus(delivery.order_id, toast, setUpdate, update,status);
  };

  const handleNextPage = () => {
    setOffset((prevOffset) => prevOffset + limit);
  };

  const handlePreviousPage = () => {
    setOffset((prevOffset) => Math.max(prevOffset - limit, 0));
  };

  return (
    <div className="h-full flex flex-col bg-gray-50 overflow-y-auto w-full md:rounded-tl-2xl p-4">
      <ToastContainer />
      <div className="w-full flex justify-between items-center border-b-2 pb-2 mb-4 ">
        <h1 className="text-xl md:text-2xl font-semibold">
          <i className="fa-solid fa-truck mr-2" /> Delivery
        </h1>
      </div>

      {/* Updated navigation for tabs */}
      <div>
        <nav className=" mb-6  overflow-x-auto  text-nowrap ">
          {statuses.map((item) => (
            <button
              key={item.id}
              className={`px-4 py-2 rounded-t-lg text-md font-medium transition-colors ${
                status === item.id
                  ? "border-b-2 border-blue-600 text-blue-600"
                  : "text-gray-500 hover:text-blue-600 hover:border-blue-600"
              }`}
              onClick={() => handleStatusChange(item.id)}
              style={{ textDecoration: "none" }} // Prevents line-through effect
            >
              {item.label}
            </button>
          ))}
        </nav>
      </div>

      {loading ? (
        <div className="flex items-center justify-center h-full">
          <Loading />
        </div>
      ) : (
        <>
          {error ? (
            <div className=" flex items-center justify-center h-100">
              <p className="text-center text-red-500">{error}</p>
            </div>
          ) : (
            <>
              {currentDeliveries.length === 0 ? (
                <div className=" flex items-center justify-center h-100">
                  No deliveries found.
                </div>
              ) : (
                <>
                  <div className="flex flex-col overflow-y-auto border rounded-lg p-4 bg-white">
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                      {currentDeliveries.map((delivery) => (
                        <DeliveryDetailBlock
                          delivery={delivery}
                          key={delivery.id}
                          handleStatusUpdate={handleStatusUpdate}
                          status={status}
                        />
                      ))}
                    </div>
                    <div className="flex justify-between mt-6">
                      <button
                        onClick={handlePreviousPage}
                        disabled={offset === 0}
                        className="px-4 py-2 rounded-lg bg-gray-200 text-gray-700 disabled:bg-gray-100 disabled:cursor-not-allowed hover:bg-gray-300 transition"
                      >
                        Previous
                      </button>
                      <button
                        onClick={handleNextPage}
                        disabled={currentDeliveries.length < limit}
                        className="px-4 py-2 rounded-lg bg-gray-200 text-gray-700 hover:bg-gray-300 transition"
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export const DeliveryDetailBlock = ({
  delivery,
  handleStatusUpdate,
  status,
}) => {
  return (
    <div className="p-4 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow">
      {/* Order Info */}
      <div className="mb-4">
        <p className="text-xl font-semibold mb-2">
          Order ID: {delivery.order_id}
        </p>
        <p className="text-sm text-gray-600 mb-1">
          <strong>Updated At:</strong>{" "}
          {new Date(delivery.order_updated_at).toLocaleString()}
        </p>
      </div>

      {/* Book Details */}
      <div className="mb-4">
        <p className="text-lg font-semibold text-gray-700 mb-2">Book Details</p>
        <p className="text-sm text-gray-600 mb-1">
          <strong>Book Name:</strong> {delivery.book_name}
        </p>
        <p className="text-sm text-gray-600 mb-1">
          <strong>Book ID:</strong> {delivery.book_id}
        </p>
        <p className="text-sm text-gray-600 mb-1">
          <strong>Book Entity ID:</strong> {delivery.book_entity_id}
        </p>
      </div>

      {/* Delivery Information */}
      <div className="mb-4">
        <p className="text-lg font-semibold text-gray-700 mb-2">
          Delivery Details
        </p>
        <p className="text-sm text-gray-600 mb-1">
          <strong>Name:</strong> {delivery.delivery_person_name}
        </p>
        <p className="text-sm text-gray-600 mb-1 break-words">
          <strong>Address:</strong> {delivery.delivery_address}
        </p>
        <p className="text-sm text-gray-600 mb-1">
          <strong>Contact:</strong> {delivery.delivery_phone_number}
        </p>
      </div>

      {/* Action Button */}
      <div className="text-center mt-6">
        <button
          className={`px-4 py-2 w-full rounded-lg ${
            status === "DELIVERED" ||
            status === "CANCELLED" ||
            status === "RETURNED"
              ? "bg-gray-300 cursor-not-allowed"
              : "bg-blue-500"
          } text-white hover:${
            status === "DELIVERED" ||
            status === "CANCELLED" ||
            status === "RETURNED"
              ? "bg-gray-300"
              : "bg-blue-600"
          } transition-colors duration-200`}
          onClick={() => handleStatusUpdate(delivery)}
          disabled={
            status === "DELIVERED" ||
            status === "CANCELLED" ||
            status === "RETURNED"
          }
        >
          Update Status
        </button>
      </div>
    </div>
  );
};

export default DeliveryComponent;
