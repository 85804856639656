import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { verifyUserLogin } from "../UserAuthentication/LoginUtils";
const ProtectedRoutes = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    verifyUserLogin({ navigate });
  });

  return <>{props.children}</>;
};

export default ProtectedRoutes;
