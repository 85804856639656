import "react-toastify/dist/ReactToastify.css";
export const DividerWithText = ({ text }) => {
  return (
    <div className="flex items-center justify-center my-4">
      <div className="flex-grow border-t border-gray-300"></div>
      <span className="flex-shrink mx-4 text-gray-400">{text}</span>
      <div className="flex-grow border-t border-gray-300"></div>
    </div>
  );
};
