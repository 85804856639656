import React, { useEffect, useState } from "react";
import UserInfoComponent from "./UserInfo";
import SubscriptionComponent from "./SubscriptionComponent";
import AddressSelection from "../Order/AddressComponent";

const UserProfileComponent = ({ setSelectedTab }) => {
  const [activeTab, setActiveTab] = useState("profile");

  const tabs = [
    {
      name: "Profile",
      component: <UserInfoComponent />,
      id: "profile",
    },
    {
      name: "Subscription",
      component: <SubscriptionComponent />,
      id: "subscription",
    },
    {
      name: "Address",
      component: <AddressSelection />,
      id: "address",
    },
  ];

  const handleTabClick = (tab) => {
    setActiveTab(tab.id);
  };

  useEffect(() => {
    setSelectedTab("profile");
  }, []);
  return (
    <div className="w-full h-full bg-gray-100 p-4 flex justify-center items-start md:rounded-tl-2xl overflow-auto">
      <div className="w-full max-w-4xl  rounded-lg  overflow-auto border-1 ">
        <nav className="border-b flex justify-between items-center  bg-slate-300  backdrop-blur-sm  ">
          <ul
            className="flex flex-wrap -mb-px text-sm font-medium text-center"
            id="myTab"
            data-tabs-toggle="#myTabContent"
            role="tablist"
          >
            {tabs.map((tab) => (
              <>
                <li className="mr-2" role="presentation">
                  <button
                    className={`inline-block p-4 rounded-t-lg border-b-2 ${
                      activeTab === tab.id
                        ? "text-blue-600 border-blue-600 "
                        : "text-gray-500 border-transparent hover:text-gray-600 hover:border-gray-300 "
                    }`}
                    onClick={() => handleTabClick(tab)}
                    role="tab"
                  >
                    {tab.name}
                  </button>
                </li>
              </>
            ))}
          </ul>
        </nav>
        <div id="myTabContent  p-4 ">
          <div className="w-full flex justify-center  items-center py-4   bg-white rounded-b-lg shadow-lg ">
            {activeTab === "profile" && <UserInfoComponent />}
            {activeTab === "subscription" && <SubscriptionComponent />}
            {activeTab === "address" && <AddressSelection />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfileComponent;
