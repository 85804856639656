import book_illustration from "../../Assets/book_illu.png";
import bg_illustration from "../../Assets/bg-illu.png";
import CustomFloatingInput from "../Utilities/CustomFloatingInput";
import cta_svg from "../../Assets/CTA.svg";
import AnimatedWordCycle from "./AnimatedWordCycle";

const Card1 = ({ inviteCode, setInviteCode, handleClick }) => {
  return (
    <div className="flex justify-center items-center bg-white w-full flex-row  rounded-t-2xl">
      <div className="w-1/2 flex flex-col px-10 py-4">
        <div className="flex items-center  mb-6">
          <i className="fa fa-users fa-lg text-customBlue"></i>
          <span className="ml-2  text-sm">20 users already joined!</span>
        </div>
        <div className="flex   mb-6 flex-col">
          <div>
            <AnimatedWordCycle />
          </div>
          <div>
            {/* Description */}
            <p className="text-gray-600 mb-8">
              Rent your favourite books, and engage in meaningful conversations
              with the characters that inspire you.
            </p>
          </div>
        </div>
        <div className="flex   mb-6 flex-col">
          <div>
            <div className="text-sm text-gray-600 mb-2">
              Got an Invite? Join Instantly!
            </div>
          </div>
          <div className="w-2/3 flex flex-row items-center">
            <div>
              <CustomFloatingInput
                required={true}
                type="text"
                id="username"
                value={inviteCode}
                onChange={(e) => {
                  setInviteCode(e.target.value);
                }}
                text="Enter invite code!"
                icon="fa-solid fa-user-plus"
              />
            </div>
            <div>
              <img
                src={cta_svg}
                alt="CTA"
                className=" w-4/6 ml-2 "
                onClick={() => handleClick()}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w-1/2 p-4 relative">
        {/* Background image using Tailwind and inline styles if needed */}
        <img
          src={bg_illustration}
          alt="Background Illustration"
          className="w-full h-full absolute right-0 top-0 object-cover z-0  "
        />

        {/* Overlayed image */}
        <img
          src={book_illustration}
          alt="Book Illustration"
          className="w-3/4 relative object-cover z-10 translate-x-20"
        />
      </div>
    </div>
  );
};

export default Card1;
