import React from "react";
import image_hero1 from "../../Assets/Image Hero.png";
import bg_illustration from "../../Assets/bg-illu.png";

const Card2 = ({ cardData }) => {
  const reverse = false;
  return (
    <div
      className={`flex w-full flex-col-reverse ${
        cardData.reverse ? "md:flex-row-reverse" : "md:flex-row"
      } rounded-t-2xl`}
    >
      {/* Left Column for Text */}
      <div className="md:w-1/2 flex flex-col justify-center p-8 w-full">
        <div className="text-start">
          <div className="text-4xl font-semibold text-black mb-4">
            <span className="text-white font-bold ">{cardData.title}</span>{" "}
            {cardData.title2}
          </div>
        </div>
        <div>
          <p className="text-lg text-gray-800">{cardData.description}</p>
        </div>
      </div>

      {/* Right Column for Image */}
      <div
        className={`md:w-1/2 flex ${
          cardData.reverse ? "justify-start" : "justify-end"
        } items-center '}`}
      >
        <img src={cardData.img} alt="hero" className="" />
      </div>
    </div>
  );
};

export default Card2;
