import { useNavigate } from "react-router-dom";
import { handleBookmarkClick, handleRemoveBookmark } from "./BooksUtil";

const BookDetailComponent = ({ book, onClose, isBookmarked, setBookmarks }) => {
  const navigate = useNavigate();
  if (!book) return null;
  let isBookmarkedVar = isBookmarked;

  const orderBook = () => {
    if (book) {
      navigate(`/order?book_id=${book.id}`);
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-75 flex justify-center items-center z-50 px-2">
      <div className="bg-white rounded-lg shadow-xl overflow-hidden w-full max-w-xl ">
        <div className="flex justify-between items-center bg-gray-200 p-4 rounded-t-lg">
          <div className="flex flex-row items-center">
            <div className="mr-2 cursor-pointer p-2 rounded hover:bg-gray-300 transition-colors">
              {isBookmarkedVar ? (
                <i
                  className="fa-solid fa-bookmark text-xl text-indigo-900"
                  onClick={() => {
                    handleRemoveBookmark(book, setBookmarks);
                    isBookmarkedVar = false;
                  }}
                />
              ) : (
                <i
                  className="fa-solid fa-bookmark text-gray-400 text-xl hover:text-indigo-600"
                  onClick={() => {
                    handleBookmarkClick(book);
                    isBookmarkedVar = true;
                  }}
                />
              )}
            </div>
            <div className="flex flex-col">
              <h2 className="font-bold text-lg md:text-xl text-gray-800">
                {book.title}
              </h2>
              <span className="text-xs md:text-sm text-gray-600">
                -{book.author}
              </span>
            </div>

          </div>
          <button
            className="text-2xl font-bold text-gray-800 hover:text-gray-900"
            onClick={onClose}
          >
            &times;
          </button>
        </div>
        <div className="flex flex-col  mt-2 p-2 ">
          <div className="flex  justify-center ">
            <img
              src={book.image_url}
              alt={book.title}
              className="w-1/2 h-auto rounded-b-lg md:rounded-b-none md:rounded-r-lg"
            /></div>

          <div className="p-4 flex flex-col justify-between flex-grow">
            <div>
              <div className="flex justify-between py-2 text-sm text-gray-600">
                <span>
                  Genre: {book.genre}
                </span>
                <span>
                  Pages: { book.pages}
                </span>
              </div>
              <div
                className=" mt-2 pr-2 overflow-y-auto text-gray-600"
                style={{ maxHeight: "200px" }}
              >
                {book.description}
              </div>
            </div>
            <div className="mt-4 flex justify-end">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition-colors text-sm md:text-base"
                onClick={orderBook}
              >
                Rent Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookDetailComponent;
