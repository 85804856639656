import React, { useState } from "react";
import { Link } from "react-router-dom";
import CustomFloatingInput from "../Utilities/CustomFloatingInput";
import { fetchGetDataNoAuth } from "../Utilities/FetchUtils";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { response, error } = await fetchGetDataNoAuth(
        `user/initiate_reset_password?username=${email}`
      );
      if (response) {
        setSubmitted(true);
      } else {
        setErrorMessage("Email Address Not Found!");
      }
    } catch (error) {
      console.error("Error occurred:", error);
      setErrorMessage("Email Address Not Found!");
    }
  };

  return (
    <div className="flex items-center justify-center h-full bg-gray-100">
      <div className="max-w-md w-full bg-white shadow-xl rounded-xl overflow-hidden">
        <div className="p-6">
          <h2 className="text-2xl font-bold mb-4 text-center">
            Forgot Your Password?
          </h2>
          {errorMessage && (
            <div className="text-lg mb-4 text-center text-red-500">
              {errorMessage}
            </div>
          )}
          {!submitted && (
            <>
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <CustomFloatingInput
                    type="email"
                    text="Email"
                    id="email"
                    required
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setErrorMessage("");
                    }}
                  />
                </div>
                <button
                  type="submit"
                  className="w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Reset Password
                </button>
              </form>
              <div className="text-center mt-4">
                <Link to="/login" className="text-blue-500 hover:underline">
                  Back to Login
                </Link>
              </div>
            </>
          )}
          {submitted && (
            <p className="text-lg mb-4 text-center text-gray-700">
              We've sent instructions to reset your password to {email}.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
