import React, { useState } from "react";
import CustomFloatingInput from "../Utilities/CustomFloatingInput";
import { isValidPassword } from "../UserAuthentication/SignupUtilities";
import { changePassword } from "./UserUtils";
import { ChangePasswordRequest } from "../Serializers/SerializerModels";

const ChangePasswordComponent = ({ setShowChangePassword }) => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isCurrentPasswordValid, setIsCurrentPasswordValid] = useState(true);
  const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState(true);
  const [passwordShown, setPasswordShown] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState(false);

  const handleChangePassword = async () => {
    // Handle password change logic here
    if (
      isCurrentPasswordValid &&
      isPasswordValid &&
      isConfirmPasswordValid &&
      password !== ""
    ) {
      const changePasswordRequest = new ChangePasswordRequest(
        currentPassword,
        password,
        confirmPassword
      );
      changePassword({
        changePasswordRequest,
        setErrorMessage,
        setSuccessMessage,
      });
    }
  };

  const handleCurrentPasswordChange = (e) => {
    const newPassword = e.target.value;
    setCurrentPassword(newPassword);
    setIsCurrentPasswordValid(isValidPassword(newPassword));
  };
  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setIsPasswordValid(isValidPassword(newPassword));
    if (newPassword === confirmPassword) {
      setIsConfirmPasswordValid(true);
    }
  };
  const handleConfirmPasswordChange = (e) => {
    const newPassword = e.target.value;
    setConfirmPassword(newPassword);
    setIsConfirmPasswordValid(password === newPassword);
  };
  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <div className="max-w-lg w-full  p-6 mb-4">
      <div className="flex justify-between mb-4">
        <div>
          <button
            className="text-gray-600 hover:text-gray-800"
            onClick={() => setShowChangePassword(false)}
          >
            <i className="fas fa-arrow-left"></i>
          </button>
        </div>
        <div>
          <h1 className="text-2xl text-start font-bold text-gray-800">
            Change Password
          </h1>
        </div>
      </div>
      {successMessage ? (
        <>
          <div className="flex items-center justify-center ">
            <div className=" p-6 max-w-md w-full">
              <div className="flex items-center justify-center mb-4">
                <i className="fas fa-check-circle text-green-500 text-3xl"></i>
                <p className="text-gray-700 text-lg">Password change successful.</p>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div>
          <div className="mb-4">
            <CustomFloatingInput
              type={"password"}
              text="Current Password"
              id="password"
              required
              value={currentPassword}
              onChange={handleCurrentPasswordChange}
              isValid={isCurrentPasswordValid}
            />
          </div>
          <div className="mb-4">
            <CustomFloatingInput
              type={passwordShown ? "text" : "password"}
              text="New Password"
              id="password"
              required
              value={password}
              onChange={handlePasswordChange}
              isValid={isPasswordValid && password !== currentPassword}
              togglePassword={togglePasswordVisibility}
            />
          </div>
          <div className="mb-6">
            <CustomFloatingInput
              type="password"
              text="Confirm Password"
              id="confirmPassword"
              required
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              isValid={isConfirmPasswordValid}
            />
          </div>
          <div>
            {errorMessage !== "" && (
              <div className="mb-4 text-sm text-red-500">{errorMessage}</div>
            )}
            <button
              type="submit"
              className={` ${
                isPasswordValid &&
                isCurrentPasswordValid &&
                isConfirmPasswordValid
                  ? "bg-blue-500 hover:bg-blue-600"
                  : "bg-gray-500"
              } text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-colors`}
              onClick={handleChangePassword}
              //   disabled={!isPasswordValid || !isCurrentPasswordValid || !isConfirmPasswordValid}
            >
              Change Password
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChangePasswordComponent;
