import React, { useEffect, useState } from "react";
import DidYouKnow from "../Extras/DidYouKnowComponent";
import BooksScrollable from "../Books/BooksScrollable";
import QuoteOfTheDay from "../Extras/QuotesComponent";
import { getBookByGenre } from "./HomeUtils";
import { useNavigate } from "react-router-dom";
import Loading from "../Common/Loading";

const HomeComponent = ({ setSelectedTab }) => {
  const [genre1Books, setGenre1Books] = useState([]);
  const [genre2Books, setGenre2Books] = useState([]);
  const [trendingBooks, setTrendingBooks] = useState([]);
  const [scienceBooks, setScienceBooks] = useState([]);
  const [romanceBooks, setRomanceBooks] = useState([]);
  const [selfHelpBooks, setSelfHelpBooks] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const handleSearchRedirect = (searchTerm) => {
    if (searchTerm) navigate(`/search?query=${encodeURIComponent(searchTerm)}`);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearchRedirect(searchTerm);
    }
  };

  useEffect(() => {
    setSelectedTab("home");
    if (!trendingBooks.length) getBookByGenre("Trending", setTrendingBooks, setIsLoading);
    if (!genre1Books.length) getBookByGenre("Fiction", setGenre1Books, setIsLoading);
    if (!genre2Books.length) getBookByGenre("Fantasy", setGenre2Books, setIsLoading);
    if (!scienceBooks.length) getBookByGenre("Science", setScienceBooks, setIsLoading);
    if (!romanceBooks.length) getBookByGenre("Romance", setRomanceBooks, setIsLoading);
    if (!selfHelpBooks.length) getBookByGenre("Self-Help", setSelfHelpBooks, setIsLoading);
  }, []);

  return (
    <div className="flex flex-col h-full bg-white overflow-hidden  w-full md:rounded-tl-2xl">
      {isLoading ? (
        <Loading />
      ) : (
          <div className="flex flex-col h-full overflow-y-auto overflow-x-clip px-4 sm:px-8 lg:px-12">
          <div className="flex justify-center mt-4">
            <div className="flex items-center w-full max-w-xl mt-4 border rounded-lg bg-gray-50 shadow transition-all hover:shadow-lg">
              <input
                className="flex-grow p-2 text-lg placeholder-gray-500 focus:outline-none"
                type="text"
                placeholder="Search books by name/author/genre"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyPress={handleKeyPress}
              />
              <button
                className="p-4 text-gray-500 hover:text-gray-700"
                onClick={() => handleSearchRedirect(searchTerm)}
                aria-label="Search books"
              >
                <i className="fa-solid fa-search" />
              </button>
            </div>
          </div>
          <div>
            <DidYouKnow />
          </div>
          <div className="my-6">
            <BooksScrollable
              books={trendingBooks.slice(0, 6)}
              sectionTitle="New Addition"
              isLoading={isLoading}
            />
          </div>
          <div>
            <QuoteOfTheDay />
          </div>
          <div className="my-6">
            <BooksScrollable
              books={trendingBooks}
              sectionTitle="Trending Books"
              isLoading={isLoading}
            />
            </div>
            <div className="my-6">
              <BooksScrollable books={selfHelpBooks} sectionTitle="Self-Help" isLoading={isLoading} />
            </div>

          <div className="my-6">
            <BooksScrollable
              books={genre1Books}
              sectionTitle="Fiction"
              isLoading={isLoading}
            />
          </div>
          <div className="my-6">
            <BooksScrollable
              books={genre2Books}
              sectionTitle="Fantasy"
              isLoading={isLoading}
            />
          </div>
          <div className="my-6">
            <BooksScrollable
              books={romanceBooks}
              sectionTitle="Romance"
              isLoading={isLoading}
            />
          </div>
          <div className="my-6">
            <BooksScrollable
              books={scienceBooks}
              sectionTitle="Science"
              isLoading={isLoading}
            />
            </div>
            
        </div>
      )}
    </div>
  );
};

export default HomeComponent;
