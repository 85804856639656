import { useState, useEffect } from "react";
import { bookFacts } from "./Facts";
// import { Default } from "react-toastify/dist/utils";
const DidYouKnow = () => {
  const [fact, setFact] = useState("");
  const [fade, setFade] = useState(false);

  useEffect(() => {
    const randomFact = bookFacts[Math.floor(Math.random() * bookFacts.length)];
    setFact(randomFact);
    setFade(true);
    const timeoutId = setTimeout(() => setFade(false), 5000);
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div className="flex flex-col  bg-gradient-to-r from-customYellow to-gray-300 p-4 rounded-lg shadow-md m-4">
      <div>
        <h3 className="text-md md:text-lg font-bold ">
          {" "}
          <i className="fa fa-lightbulb fa-md mr-2" />
          Did You Know?
        </h3>{" "}
      </div>
      <div className="pt-2 md:me-4 md:w-10/12 ">
        <i className="fa fa-quote-left  text-sm md:text-lg " />
      </div>
      <div className=" text-center text-sm md:text-lg ps-4 md:ps-6 md:me-4 font-mono  ">
        {fact}
      </div>
      <div className="pb-2  ps-2 md:me-4  text-right">
        <i className="fa fa-quote-right text-sm md:text-lg " />
      </div>
    </div>
  );
};

export default DidYouKnow;
