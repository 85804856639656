import { useEffect, useState } from "react";

const AnimatedWordCycle = () => {
  const words = ["Rentkar", "Readkar", "Returnkar" ];
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [typedText, setTypedText] = useState("");
  const [isDeleting, setIsDeleting] = useState(false); // Tracks if we are deleting
  const [speed, setSpeed] = useState(150); // Speed of typing and deleting
  const [loop, setLoop] = useState(0); // Tracks when the typing loop restarts

  useEffect(() => {
    const handleTyping = () => {
      const currentWord = words[currentWordIndex];
      if (isDeleting) {
        // If deleting, remove one character
        setTypedText((currentText) => currentText.slice(0, -1));
        setSpeed(100); // Speed of deleting
      } else {
        // If typing, add one character
        setTypedText((currentText) =>
          currentWord.slice(0, currentText.length + 1)
        );
        setSpeed(150); // Speed of typing
      }

      // If word is fully typed, pause before deleting
      if (!isDeleting && typedText === currentWord) {
        setTimeout(() => setIsDeleting(true), 1000); // Pause for 1s before deleting
      } else if (isDeleting && typedText === "") {
        // If word is fully deleted, move to the next word
        setIsDeleting(false);
        setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length); // Cycle through words
      }
    };

    const typingTimeout = setTimeout(handleTyping, speed);

    return () => clearTimeout(typingTimeout); // Clear timeout on cleanup
  }, [typedText, isDeleting, speed, currentWordIndex]);

  return (
    <div className="text-5xl font-bold mb-4 min-h-[48px] text-customBlue ">
      <span>{typedText}</span>
      {/* <span className="blinking-cursor">|</span> */}{" "}
    </div>
  );
};

export default AnimatedWordCycle;
