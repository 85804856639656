import { useState } from "react";
import { requestBook } from "./RequestBookUtils";

import { useNavigate } from "react-router-dom";

const RequestBookItem = ({
  key,
  book,
  toast,
  setSuccessModal,
  setSelectedBook,
}) => {
  const {
    title,
    authors,
    language,
    imageLinks,
    publisher,
    publishedDate,
    description,
  } = book.volumeInfo;
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Toggle the modal visibility
  const handleToggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  // Handle Request Button Click
  const handleRequestBook = () => {
    // Implement your request logic here, e.g., send a request to your server
    requestBook({ book, toast, setSuccessModal, setSelectedBook });
  };

  return (
    <div className="w-5/6 flex items-center justify-center">
      <div
        className="bg-white shadow-md rounded-lg p-4 mb-4 w-full max-w-md md:max-w-lg hover:shadow-lg transition-shadow duration-300 transform hover:-translate-y-1 hover:scale-105 cursor-pointer items-center"
        onClick={handleToggleModal}
      >
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="flex justify-center md:justify-start">
            <img
              src={imageLinks?.thumbnail || "https://via.placeholder.com/128"}
              alt={title}
              className="w-32 h-48 md:w-full md:h-auto object-contain rounded-md"
            />
          </div>
          <div className="col-span-2 flex flex-col justify-between">
            <h3 className="font-bold text-lg text-gray-800 md:text-xl">
              {title}
            </h3>
            <p className="text-gray-600 mt-1 text-sm md:text-base">
              {authors ? authors.join(", ") : "Unknown Author"}
            </p>
            <div className="mb-4">
              <p className="text-gray-600 line-clamp-3">
                <strong>Description:</strong>{" "}
                {description || "No description available"}
              </p>
            </div>

            {/* Request Button */}
            <button
              className="mt-2 bg-blue-500 text-white py-1 px-4 rounded hover:bg-blue-600 transition duration-200"
              onClick={(e) => {
                e.stopPropagation(); // Prevent triggering the modal on button click
                handleRequestBook();
              }}
            >
              Request
            </button>
          </div>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          onClick={handleToggleModal}
        >
          <div
            className="bg-white rounded-lg p-6 w-11/12 max-w-lg shadow-lg relative"
            onClick={(e) => e.stopPropagation()} // Prevent modal close when clicking inside
          >
            <button
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
              onClick={handleToggleModal}
            >
              &times;
            </button>
            <h2 className="font-bold text-xl mb-2">{title}</h2>
            <p className="text-gray-600 mb-2">
              <strong>Authors:</strong>{" "}
              {authors ? authors.join(", ") : "Unknown Author"}
            </p>
            <p className="text-gray-600 mb-2">
              <strong>Language:</strong> {language || "Unknown"}
            </p>
            {publisher && (
              <p className="text-gray-600 mb-2">
                <strong>Publisher:</strong> {publisher}
              </p>
            )}
            {publishedDate && (
              <p className="text-gray-600 mb-2">
                <strong>Published:</strong> {publishedDate}
              </p>
            )}
            {description && (
              <p className="text-gray-600 mb-4 max-h-40 overflow-y-auto">
                <strong>Description:</strong> {description}
              </p>
            )}
            {/* Request Button in Modal */}
            <button
              className="bg-blue-500 text-white py-2 px-6 rounded hover:bg-blue-600 transition duration-200"
              onClick={handleRequestBook}
            >
              Request
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default RequestBookItem;
