import { useContext, useEffect, useState } from "react";
import CustomFloatingInput from "../Utilities/CustomFloatingInput";
import { copyToClipboard, getCurrentUser, handleLogout } from "./UserUtils";
import { useNavigate } from "react-router-dom";
import ChangePasswordComponent from "./ChangePassword";
import { UserContext } from "../../App";

const UserInfoComponent = () => {
  const [editable, setEditable] = useState(false);
  const [editedInfo, setEditedInfo] = useState(null);
  const [userInfo, setUserInfo] = useState({});
  const [showChangePassword, setShowChangePassword] = useState(false);
  const navigate = useNavigate();
  const { setIsUserLoggedIn } = useContext(UserContext);


  useEffect(() => {
    async function fetchData() {
      getCurrentUser({ setUserInfo, setError: () => { } });

    }
    fetchData();
  }, []);
  const handleChange = (e) => {
    setEditedInfo({ ...editedInfo, [e.target.id]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert("Profile updated!");
  };
  return (
    <div className="max-w-lg w-full p-6 mb-4 mx-auto   rounded-lg shadow-xl border-1  ">
      {showChangePassword ? (
        <>
          <ChangePasswordComponent
            setShowChangePassword={setShowChangePassword}
          />
        </>
      ) : (
        <>
          <div className="flex flex-row justify-end">

            <i
              className={`fa-solid fa-pen-to-square text-xl cursor-pointer  hover:text-gray-700 ${editable ? "text-gray-700" : "text-gray-500"
                }`}
              onClick={() => {
                if (editable) {
                  setEditedInfo(null);
                  setEditable(false);
                } else {
                  setEditedInfo({ ...userInfo });
                  setEditable(true);
                }
              }}
            ></i>
          </div>

          <div className="flex flex-col items-center  ">
            <img
              className=" h-28 w-28 md:w-32 md:h-32  rounded-full border-4 border-blue-500 object-cover mb-6"
              src={userInfo.profileImage}
              alt={userInfo.name}
            />
            <div className="w-full grid grid-cols-1 gap-4 mb-4">
              {editable ? (
                <>
                  <CustomFloatingInput
                    type="text"
                    text="First Name"
                    id="first_name"
                    required
                    value={editedInfo.first_name}
                    onChange={handleChange}
                    disabled={!editable}
                  />
                  <CustomFloatingInput
                    type="text"
                    text="Last Name"
                    id="last_name"
                    required
                    value={editedInfo.last_name}
                    onChange={handleChange}
                    disabled={!editable}
                  />
                  <CustomFloatingInput
                    type="email"
                    text="Email"
                    id="email"
                    required
                    value={editedInfo.email}
                    disabled={true}
                  />
                  <CustomFloatingInput
                    type="number"
                    text="Phone"
                    id="phone_number"
                    required
                    value={editedInfo.phone_number}
                    onChange={handleChange}
                    disabled={!editable}
                  />
                </>
              ) : (
                <>
                  <CustomFloatingInput
                    type="text"
                    text="First Name"
                    id="name"
                    required
                    value={userInfo.first_name}
                    disabled={!editable}
                  />
                  <CustomFloatingInput
                    type="text"
                    text="Last Name"
                    id="name"
                    required
                    value={userInfo.last_name}
                    disabled={!editable}
                  />
                  <CustomFloatingInput
                    type="email"
                    text="Email"
                    id="email"
                    required
                    value={userInfo.email}
                    disabled={true}
                  />
                  <CustomFloatingInput
                    type="text"
                    text="Phone"
                    id="phone"
                    required
                    value={userInfo.phone_number}
                    disabled={!editable}
                  />
                </>
              )}
            </div>

            <div
              className="mb-4   rounded-xl  p-2 justify-start w-full  "
              onClick={() => {
                copyToClipboard(userInfo.invite_code);
              }}
            >
              {" "}
              <CustomFloatingInput
                type="text"
                text="Invite Code"
                id="invite_code"
                required
                value={userInfo.invite_code}
                disabled={true}
                icon={"fa-solid fa-key"}
              />
            </div>
            <div className={`w-full flex ${"justify-between"} `}>
              {editable ? (
                <button
                  className="mt-2 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-colors"
                  onClick={handleSubmit}
                >
                  Save Changes
                </button>
              ) : (
                <button
                  onClick={() => {
                    handleLogout(navigate, setIsUserLoggedIn);
                  }}
                  className="mt-2 bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-colors"
                >
                  Logout
                </button>
              )}
              <button
                onClick={() => setShowChangePassword(true)}
                type="button"
                className="mt-2 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-colors"
              >
                Reset Password
              </button>
            </div>
          </div>
        </>
      )}

    </div>
  );
};
export default UserInfoComponent;
