import { signupHandler } from "../Utilities/FetchUtils";

export const isValidPassword = (password) => {
  const regex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  return regex.test(password) && password.length >= 8;
};

export const isValidEmail = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

export const handleSignup = async (
  signupRequest,
  setIsLoading,
  setShowSuccessModal
) => {
  setIsLoading(true);

  const { response, error } = await signupHandler(signupRequest);
  if (response) {
    setShowSuccessModal(true);
  }

  setIsLoading(false);
};
