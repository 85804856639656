import { useEffect, useState } from "react";
import { getSubscriptionDetails } from "./UserUtils";
import Loading from "../Common/Loading";


const SubscriptionComponent = () => {
  const [subscriptionDetail, setSubscriptionDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(true);
    getSubscriptionDetails({ setSubscriptionDetail, setIsLoading });

  },[])
  return <>{
    isLoading ? <><Loading /></> : <>
      {subscriptionDetail ? (
        <>
          <div className="max-w-lg w-full bg-white rounded-xl shadow-lg p-6 transition duration-300 ease-in-out hover:shadow-xl">
            <div className="flex flex-col items-center">
              {/* Subscription Type */}
              <div className="w-full flex">
                <h1 className="text-start mb-4 font-semibold text-2xl  text-gray-800">
                  {subscriptionDetail.subscription_type}
                </h1>
              </div>

              {/* Active Till */}
              <div className="w-full flex justify-between items-center p-2 bg-blue-50 rounded-md mb-4">
                <span className="text-gray-600 text-lg">Active till:</span>
                <span className="font-semibold text-gray-800">
                  {subscriptionDetail.end_date}
                </span>
              </div>

              {/* Remaining Books */}
              <div className="w-full flex justify-between items-center p-2 bg-blue-50 rounded-md mb-4">
                <span className="text-gray-600 text-lg">Remaining books:</span>
                <span className="font-semibold text-gray-800">
                  {subscriptionDetail.remaining_book_count}
                </span>
              </div>

              {/* Allowed Books at a Time */}
              <div className="w-full flex justify-between items-center p-2 bg-blue-50 rounded-md mb-4">
                <span className="text-gray-600 text-lg">
                  Allowed books at a time:
                </span>
                <span className="font-semibold text-gray-800">
                  {subscriptionDetail.allowed_book_at_once}
                </span>
              </div>

              {/* Books Rented */}
              <div className="w-full flex justify-between items-center p-2 bg-blue-50 rounded-md mb-4">
                <span className="text-gray-600 text-lg">Books Rented:</span>
                <span className="font-semibold text-gray-800">
                  {subscriptionDetail.book_issued_count}
                </span>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="flex justify-center items-center h-full">
            <div className="max-w-lg w-full bg-white rounded-xl shadow-lg p-6 transition duration-300 ease-in-out hover:shadow-xl">
              <div className="flex flex-col items-center">
                <h1 className="text-start mb-4 font-semibold text-2xl text-blue-800">
                  Oops!! No Active Subscription
                </h1>
                <div className="text-center mb-4">
                  You don't have any active subscription.
                </div>
                <div className="text-center mb-4">
                  Subscribe to a plan now to get started:
                </div>
                <a href="/subscription" className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                  Browse Plans
                </a>
              </div>
            </div>
          </div>
        </>
      )}</>
  }
  
  </>
};

export default SubscriptionComponent;
