const CustomFloatingSelect = ({
  values,
  isValid = true,
  disabled,
  label,
  id,
  onChange,
}) => {
  return (
    <div className="relative">
      <select
        id={id}
        className={`block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border ${
          isValid ? "border-gray-300" : "border-red-500"
        } appearance-none focus:outline-none focus:ring-0 ${
          isValid ? "focus:border-blue-600" : "focus:border-red-500"
        } peer ${disabled ? "opacity-70" : ""}`}
        onChange={(e) => onChange(e)}
      >
        {values.map((value) => (
          <option value={value.value}>{value.label}</option>
        ))}
      </select>
      <label
        htmlFor={id}
        className="absolute ms-1 text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4"
      >
        {label}
      </label>
    </div>
  );
};

export default CustomFloatingSelect;
