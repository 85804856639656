import { BookOrderModel } from "../Serializers/SerializerModels";
import { fetchGetData, fetchPostData } from "../Utilities/FetchUtils";

export const getMyBooks = async ({ setMyBooks, setIsLoading, setError }) => {
  setIsLoading(true);
  const { response, error } = await fetchGetData("order/details");
  if (response) {
    if (response.length === 0) {
      setError("No books rented yet");
    } else {
      const booksDetail = response.map((book) => new BookOrderModel(book));
      booksDetail.sort(
        (a, b) => new Date(b.order_updated_at) - new Date(a.order_updated_at)
      );
      setMyBooks(booksDetail);
    }
  } else {
    setError("something went wrong");
  }
  setIsLoading(false);
};

export const cancelOrder = async (book, toast) => {
  const { response, error } = await fetchPostData("order/cancel", book);
  if (response) {
    toast.success("Order cancelled successfully");
  } else {
    toast.error("Something went wrong");
  }
};

export const returnOrder = async (book, toast) => {
  const { response, error } = await fetchPostData("order/return", book);
  if (response) {
    toast.success("Order marked for Pickup successfully");
  } else {
    toast.error("Something went wrong");
  }
};
