import { useState } from "react";
import { fetchGetDataNoAuth } from "../Utilities/FetchUtils";

const VerifyInvite = ({
  setIsTokenVerified,
  setInviteCode,
  inviteCode,
  tokenErrorMessage,
  setTokenErrorMessage,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleInviteCodeChange = (e) => {
    setTokenErrorMessage("");
    setInviteCode(e.target.value);
  };

  const verifyInviteCode = async () => {
    setIsLoading(true);
    if (inviteCode) {
      const { response, error } = await fetchGetDataNoAuth(
        `user/verify_invite?code=${inviteCode}`
      );
      if (response) {
        setIsTokenVerified(true);
      } else {
        setTokenErrorMessage("Not A Valid Invite Code!");
      }
    }
    setIsLoading(false);
  };
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white rounded-lg shadow-lg p-6 max-w-md w-full">
        <div className="text-center mb-4">
          Invited to the Readkar family. Please Verify the invite code. And get
          access to Readkar.
        </div>
        <div className="flex flex-col md:flex-row items-center justify-center">
          <input
            type="text"
            className="bg-gray-100 border border-gray-300 rounded-md py-2 px-4 mb-2 md:mb-0 w-full md:w-auto focus:outline-none focus:ring focus:border-blue-500"
            placeholder="Enter Invite Code"
            value={inviteCode}
            onChange={handleInviteCodeChange}
          />
          <button
            className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${
              inviteCode ? "cursor-pointer" : "cursor-not-allowed opacity-50"
            }`}
            onClick={verifyInviteCode}
            disabled={isLoading || !inviteCode}
          >
            Verify
          </button>
        </div>
        {tokenErrorMessage && (
          <div className="mt-4 text-red-500 text-center">
            {tokenErrorMessage}
          </div>
        )}
      </div>
    </div>
  );
};

export default VerifyInvite;
