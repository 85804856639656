import { DeliveryObjectModel } from "../Serializers/SerializerModels";
import { fetchGetData, fetchPostData } from "../Utilities/FetchUtils";

export const fetchDeliveries = async ({status, offset, limit, setCurrentDeliveries, setLoading, setError}) => {
    setLoading(true);
    setError(null);
    try {
        const { response, error } = await fetchGetData(
            `logistics/get?status=${status}&limit=${limit}&offset=${offset}`
          );
          if (response) {
            const deliveries = response.map((delivery) => new DeliveryObjectModel(delivery));
            setCurrentDeliveries(deliveries);
          }
    } catch (err) {
      setError("Failed to fetch deliveries");
    } finally {
      setLoading(false);
    }
  };


const getNextStatus = (status) => {
  switch (status) {
    case "TO_BUY_AND_DELIVER":
      return "DISPATCHED";
    case "TO_BE_DELIVERED":
      return "DISPATCHED";
    case "DISPATCHED":
      return "DELIVERED";
    case "DELIVERED":
      return "DELIVERED";
    case "TO_BE_PICKED_UP":
      return "PICKED_UP";
    case "PICKED_UP":
      return "RETURNED";
    case "RETURNED":
      return "RETURNED";
    case "CANCELLED":
      return "CANCELLED";
    default:
      return "TO_BE_DELIVERED";
  }
};


  export const updateStatus = async (order_id, toast,setUpdate,update,status) => {

    const { response, error } = await fetchGetData(`logistics/update?ubm_id=${order_id}`);
    if (response) {
      toast.success(`Order status changed to ${getNextStatus(status)}`);
    } else {
      let detail = await error.json()
      if(detail.detail){

        toast.error(detail.detail);
      }
      else{
        toast.error("Something went wrong");
      }
    }
    setUpdate(!update)
  };