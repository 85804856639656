import React, { useState } from 'react';
import { confirmOrder } from './AddressUtils';
import { ToastContainer, toast } from "react-toastify";

const ConfirmationPage = ({ setStep,book, address, navigate }) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleRent = () => {
    setIsLoading(true);
    confirmOrder(book, address, navigate, toast, setIsLoading);
  };
  return (
    <div className=" bg-gray-100 flex items-center justify-center">
      <ToastContainer />
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full">
        <h2 className="text-2xl font-bold mb-2">Confirm your Details</h2>

        <div className="mb-4">
          <h3 className="text-xl mb-1">Book Details:</h3>
          <img src={book.image_url} alt="Book Cover" className="mt-2 rounded" />
          <p>
            <strong>Title:</strong> {book.title}
          </p>
          <p>
            <strong>Author:</strong> {book.author}
          </p>
          <p>
            <strong>Year:</strong> {book.year}
          </p>
          <p>
            <strong>Genre:</strong> {book.genre}
          </p>
        </div>

        <div className="mb-6">
          <h3 className="text-xl mb-1">Shipping Address:</h3>
          <p>{address.name}</p>
          <p>{address.addressLine1}</p>
          {address.addressLine2 && <p>{address.addressLine2}</p>}
          <p>
            {address.city}, {address.state} {address.pincode}
          </p>
          <p>{address.country}</p>
          <p>Phone: {address.phoneNumber}</p>
        </div>

        <div className="flex justify-between">
          <button className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
            onClick={() => setStep(0)}>
            Back
          </button>
          <button
            className={`${
              isLoading ? "bg-gray-500" : "bg-blue-500 hover:bg-blue-600"
            } text-white px-4 py-2 rounded `}
            onClick={handleRent}
            disabled={isLoading}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPage;
