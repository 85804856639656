import {
  getAccessToken,
  refreshAccessToken,
} from "../UserAuthentication/AuthUtils";

export const fetchPostData = async (endpoint, reqObj) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  try {
    const response = await fetch(`${apiUrl}/${endpoint}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: reqObj.getRequest(),
    });
    if (!response.ok) {
      if (
        response.status === 403 ||
        response.status === 401 ||
        response.status === 400
      ) {
        refreshAccessToken();
        return fetchGetData(endpoint);
      } else {
        return {
          response: null,
          error: response,
        };
      }
    }
    const data = await response.json();
    return {
      response: data.results,
      error: null,
    };
  } catch (error) {
    return {
      response: null,
      error: error,
    };
  }
};

export const fetchGetData = async (endpoint) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  try {
    const response = await fetch(`${apiUrl}/${endpoint}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    if (!response.ok) {
      if (
        response.status === 403 ||
        response.status === 401 ||
        response.status === 400
      ) {
        refreshAccessToken();
        return fetchGetData(endpoint);
      } else {
        return {
          response: null,
          error: response,
        };
      }
    }
    const data = await response.json();
    return {
      response: data.results,
      error: null,
    };
  } catch (error) {
    return {
      response: null,
      error: error,
    };
  }
};

export const loginHandler = async (reqObj) => {
  const apiUrl = process.env.REACT_APP_API_URL;

  try {
    const response = await fetch(`${apiUrl}/user/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: reqObj.getRequest(),
    });
    if (!response.ok) {
      return {
        response: null,
        error: response,
      };
    }
    const data = await response.json();

    return {
      response: data.results,
      error: null,
    };
  } catch (error) {
    return {
      response: null,
      error: error,
    };
  }
};

export const signupHandler = async (reqObj) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  try {
    const response = await fetch(`${apiUrl}/user/sign_up`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: reqObj.getRequest(),
    });
    if (!response.ok) {
      return {
        response: null,
        error: response,
      };
    }
    const data = await response.json();

    return {
      response: data.results,
      error: null,
    };
  } catch (error) {
    return {
      response: null,
      error: error,
    };
  }
};

export const fetchGetDataNoAuth = async (endpoint) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  try {
    const response = await fetch(`${apiUrl}/${endpoint}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      return {
        response: null,
        error: response,
      };
    }
    const data = await response.json();
    return {
      response: data.results,
      error: null,
    };
  } catch (error) {
    return {
      response: null,
      error: error,
    };
  }
};

export const fetchPostDataNoAuth = async (endpoint, reqObj) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  try {
    const response = await fetch(`${apiUrl}/${endpoint}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: reqObj.getRequest(),
    });
    if (!response.ok) {
      return {
        response: null,
        error: response,
      };
    }
    const data = await response.json();
    return {
      response: data.results,
      error: null,
    };
  } catch (error) {
    return {
      response: null,
      error: error,
    };
  }
};
