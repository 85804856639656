import React from "react";
import { Link } from "react-router-dom";

const SuccessConfirmation = ({ email }) => {
  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="max-w-md w-full bg-white shadow-md rounded-lg p-6">
        <h2 className="text-3xl font-bold text-center mb-6">
          Sign Up Successful!
        </h2>
        <p className="text-gray-600 text-center mb-6">
          Please check your email{" "}
          <span className=" font-semibold">{email}</span> for verification. Once
          verified, you can proceed to login.
        </p>
        <Link
          to="/login"
          className="block w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-center"
        >
          Go to Login
        </Link>
      </div>
    </div>
  );
};

export default SuccessConfirmation;
