import { fetchGetDataNoAuth } from "../Utilities/FetchUtils";



export const confirmEmail = async ({ token, setIsVerified, setIsLoading }) => {
    setIsLoading(true);
    const { response, error } = await fetchGetDataNoAuth(
        `user/email_verify?token=${token}`
    );
    if (response) {
        setIsVerified(true);
    } else {
        setIsVerified(false);
    }
    setIsLoading(false);
}