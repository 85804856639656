import book_illustration from "../../Assets/book_illu.png"; // Use a different illustration
// import bg_illustration from "../../Assets/bg-illu2.png"; // Optional background image
import CustomFloatingInput from "../Utilities/CustomFloatingInput";
import cta_svg from "../../Assets/CTA.svg";
import AnimatedWordCycle from "./AnimatedWordCycle";
const MBCard1 = ({ inviteCode, setInviteCode, handleClick }) => {
  return (
    <div className="w-full bg-CustomYellow">
      <div className=" w-full  item-start justify-center flex">
        <img
          src={book_illustration}
          alt="Book Illustration"
          className="w-10/12  transform translate-y-[20%] -rotate-12"
        />
      </div>

      <div className="relative flex flex-col md:flex-row justify-center items-center bg-white w-full rounded-t-2xl shadow-lg">
        <div className="relative z-10 w-full md:w-1/2 flex flex-col px-6 py-4">
          <div className="flex items-center mb-4 md:mb-6">
            <span className=" text-xs">
              <i className="fa fa-users fa-lg text-customBlue" /> 20 users
              already joined!
            </span>
          </div>
          <div className="flex flex-col mb-4 md:mb-6">
            <div>
              <AnimatedWordCycle />
            </div>
            <div>
              <p className="text-gray-600 mb-8">
                Rent your favourite books, and engage in meaningful
                conversations with the characters that inspire you.
              </p>
            </div>
          </div>
          <div className="flex flex-col mb-4 md:mb-6">
            <div>
              <div className="text-sm text-gray-600 mb-2">
                Got an Invite? Join Instantly!
              </div>
            </div>
            <div className="w-full flex flex-row items-center">
              <div className="w-3/4">
                <CustomFloatingInput
                  required={true}
                  type="text"
                  id="username"
                  value={inviteCode}
                  onChange={(e) => {
                    setInviteCode(e.target.value);
                  }}
                  text="Enter invite code!"
                  icon="fa-solid fa-user-plus"
                />
              </div>
              <div>
                <img
                  src={cta_svg}
                  alt="CTA"
                  className=" w-4/6 ml-2  "
                  onClick={() => handleClick()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MBCard1;
