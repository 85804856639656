import { AddressModel, OrderRequest } from "../Serializers/SerializerModels";
import { fetchGetData, fetchPostData } from "../Utilities/FetchUtils";

export const getAddress = async ({ setAddress, setIsLoading }) => {
  setIsLoading(true);
  const { response, error } = await fetchGetData("user/get_addresses");
  if (response) {
    const address = response.map((address) => new AddressModel(address));
    setAddress(address);
  } else {
    setAddress([]);
  }
  setIsLoading(false);
};

export const saveAddress = async (addressRequest, setIsLoading, toast) => {
  setIsLoading(true);
  const { response, error } = await fetchPostData(
    "user/create_or_update_address",
    addressRequest
  );
  if (response) {
    toast.success("Address saved successfully");
  } else {
    toast.error("Something went wrong");
  }
  setIsLoading(false);
};

export const deleteAddress = async (address, toast) => {
  const { response, error } = await fetchGetData(
    `user/delete_address?address_id=${address.id}`
  );
  if (response) {
    toast.success("Address deleted successfully");
  } else {
    toast.error("Something went wrong");
  }
};

export const confirmOrder = async (book, address, navigate, toast) => {
  const { response, error } = await fetchPostData(
    "order/create_order",

    new OrderRequest(book.id, address.id)
  );
  if (response) {
    toast.success("Order placed successfully");
    setTimeout(() => {
      navigate(-1);
    }, 2000);
  } else {
    const data = await error.json()
    toast.error(data.detail);
    setTimeout(() => {
      navigate(-1);
    }, 5000);
  }
};



