import React, { useState } from "react";
import {
  handleSubmit,
  validateEmail,
  validateFields,
  validatePhone,
} from "./WaitlistUtils";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const WaitlistForm = () => {
  const [step, setStep] = useState(1);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    phone: "",
    firstName: "",
    lastName: "",
    booksRead: 0,
    favouriteBook: "",
    bookNextRead: "",
    booksPerMonth: 0,
  });

  const handleChange = (input) => (e) => {
    setFormData({ ...formData, [input]: e.target.value });
  };

  const validateFormPage1 = (formData, toast) => {
    if (
      !formData.email ||
      !formData.phone ||
      !formData.firstName ||
      !formData.lastName
    ) {
      toast.error("Please fill all the fields.");
      return false;
    } else if (validateEmail(formData.email) === false) {
      toast.error("Please enter a valid email.");
      return false;
    } else if (validatePhone(formData.phone) === false) {
      toast.error("Please enter a valid phone number.");
      return false;
    } else {
      return true;
    }
  };

  const validateFormPage2 = (formData, toast) => {
    if (
      !formData.booksRead ||
      !formData.favouriteBook ||
      !formData.bookNextRead ||
      !formData.booksPerMonth
    ) {
      toast.error("Please fill all the fields.");
      return false;
    } else if (
      validateFields(formData.booksRead) === false ||
      validateFields(formData.booksPerMonth) === false
    ) {
      toast.error("Please fill all the fields.");
    } else {
      return true;
    }
  };

  const nextStep = () => {
    if (validateFormPage1(formData, toast) === true) {
      handleSubmit(
        formData,
        setShowSuccessModal,
        setShowErrorModal,
        setFormData,
        setError,
        toast,
        setIsLoading
      );
    }
  };

  const prevStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  return (
    <div className="h-full bg-gray-100 flex flex-col px-4 md:px-10 items-center pt-10  "></div>
  );
};

export default WaitlistForm;
