import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Draggable from "react-draggable";
import { getRole } from "../UserAuthentication/AuthUtils";



const userRole = getRole();

export const MobileBottomNav = ({ tabs, selectedTab, setSelectedTab }) => {
  // State to manage whether the nav is collapsed or not

  const navigate = useNavigate();
  const handleOnClick = (tabId) => {
    if (tabId !== selectedTab) {
      setSelectedTab(tabId);
      // Collapse the navbar

      navigate(`/${tabId}`);
    }
  };
  return (
    <div className="h-12 bg-customYellow md:hidden  w-full ">
      <div
        className={`grid h-full grid-cols-5
          } mx-auto grid-flow-col transition-all duration-1000 `}
      >
        {tabs
            .filter((tab) => tab.acl.includes(userRole)).map((tab, index) => (
          <div
            key={index}
            className={`inline-flex flex-col items-center justify-center px-5 ${
              selectedTab === tab.id ? "bg-customOrange" : ""
            } group border-gray-600 rounded-full `}
            onClick={() => handleOnClick(tab.id)}
          >
            <i
              className={`${tab.icon} text-xl text-white dark:text-white`}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export const DesktopSidebar = ({ tabs, selectedTab, setSelectedTab }) => {
  const navigate = useNavigate();
  const handleOnClick = (tabId) => {
    if (tabId !== selectedTab) navigate(`/${tabId}`);

    setSelectedTab(tabId);
  };
  return (
    <div className="hidden md:flex md:flex-col  md:top-0 md:left-0 md:z-50 md:h-[100%] md:w-16 lg:w-20 bg-customYellow ">
      <div className="flex flex-col items-center justify-start h-full rounded mt-4">
        {/* Home Button */}

        {tabs
            .filter((tab) => tab.acl.includes(userRole)).map((tab, index) => (
          <div
            key={index}
            className={`flex flex-col items-center justify-center mb-2 ${
              selectedTab === tab.id ? "bg-customOrange" : ""
            }  p-5 group  w-full  hover:bg-customHover rounded-lg`}
            onClick={() => handleOnClick(tab.id)}
          >
            <i className={`${tab.icon} text-xl text-white `} />

            <span className="text-xs text-white `}">{tab.label}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

const NavBar = ({ tabs, selectedTab, setSelectedTab }) => {
  return (
    <>
      <MobileBottomNav
        tabs={tabs}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      <DesktopSidebar
        tabs={tabs}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
    </>
  );
};

export default NavBar;
