import { useEffect, useState } from "react";
import AddAddressComponent from "./AddAddressComponent";
import { deleteAddress, getAddress } from "./AddressUtils";
import { ToastContainer, toast } from "react-toastify";
import Loading from "../Common/Loading";

const AddressSelection = ({
  selectedAddress,
  handleAddressChange,
  onContinue,
}) => {
  const [showAddaddressModal, setShowAddaddressModal] = useState(false);
  const [address, setAddress] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getAddress({ setAddress, setIsLoading });
  }, []);
  const handleDeleteAddress = (address) => {
    deleteAddress(address, toast);
    setTimeout(() => {
      getAddress({ setAddress, setIsLoading });
    }, 2000);
  };

  const handleSelect = (address) => {
    if (onContinue) {
      handleAddressChange(address);
    } else {
    }
  };

  return (
    <>
      <ToastContainer />
      {showAddaddressModal ? (
        <AddAddressComponent
          onClose={() => {
            setTimeout(() => {
              getAddress({ setAddress, setIsLoading });
            }, 2000);
            
            setShowAddaddressModal(!showAddaddressModal)
          }}
          setAddress={setAddress}
          address={address}
          toast = {toast}
        />
      ) : (
        <div className="flex flex-col">
            {isLoading ? <>
              <Loading/>
            </> : <>
            {onContinue && (
              <h1 className="text-xl font-semibold mt-2 ms-2">
                Select an Address
              </h1>
            )}
            <div className=" m-4   bg-white rounded-xl shadow-lg  transition duration-300 ease-in-out hover:shadow-xl overflow-y-auto ">
              <div className={` w-full ${address.length === 0?`flex p-4 gap-3`:`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  p-4 gap-3`}` }>
                <div
                  className="flex flex-col border-2 rounded-lg p-4  justify-center items-center"
                  onClick={() => setShowAddaddressModal(!showAddaddressModal)}
                >
                  <i className="fa-solid fa-plus text-5xl " />
                  Add Address
                </div>
                {address.map((addr, index) => (
                  <div
                    className={`border-2 rounded-lg p-4 ${
                      addr === selectedAddress
                        ? `border-blue-500`
                        : `border-gray-300`
                    } `}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSelect(addr);
                    }}
                    key={index}
                  >
                    <div className="flex flex-col  mb-4  " key={index}>
                      <span className="mb-2">
                        <strong> {addr.name}</strong>
                      </span>

                      <span>{addr.addressLine1},</span>
                      <span>{addr.addressLine2}</span>
                      <span>
                        {addr.city}, {addr.state} {addr.pincode}
                      </span>
                      <span>{addr.country}</span>

                      <span>Phone Number: {addr.phoneNumber}</span>
                    </div>
                    <div className="flex justify-end">
                      <div
                        className="text-red-400 cursor-pointer text-end"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteAddress(addr);
                        }}
                      >
                        {" "}
                        Delete
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {onContinue && (
              <div className="flex justify-end me-4 ">
                <button
                  className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
                  onClick={onContinue}
                >
                  Continue
                </button>
              </div>
            )}
          </>}
        </div>
      )}
    </>
  );
};
export default AddressSelection;
