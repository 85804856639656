import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import {
  Page1,
  confirmationModal,
  errorModal,
  handleSubmit,
  validateEmail,
  validatePhone,
} from "./WaitlistUtils";

const WaitlistModal = ({ show, setShowWaitListModal }) => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    phone: "",
    firstName: "",
    lastName: "",
  });

  const handleChange = (input) => (e) => {
    setFormData({ ...formData, [input]: e.target.value });
  };

  const validateFormPage1 = (formData) => {
    if (
      !formData.email ||
      !formData.phone ||
      !formData.firstName ||
      !formData.lastName
    ) {
      toast.error("Please fill all the fields.");
      return false;
    } else if (!validateEmail(formData.email)) {
      toast.error("Please enter a valid email.");
      return false;
    } else if (!validatePhone(formData.phone)) {
      toast.error("Please enter a valid phone number.");
      return false;
    } else {
      return true;
    }
  };

  const handleClick = () => {
    if (validateFormPage1(formData)) {
      handleSubmit(
        formData,
        setShowSuccessModal,
        setShowErrorModal,
        setFormData,
        setError,
        toast,
        setIsLoading,
        setShowWaitListModal
      );

    }
  };

  const closeModal = () => {
    setShowWaitListModal(false);
    setError(null);
    setShowSuccessModal(false);
    setShowErrorModal(false);
    setFormData({
      email: "",
      phone: "",
      firstName: "",
      lastName: "",
    })
  };

  if (!show) return null; // If `show` is false, don't render the modal.

  return (
    <>
      <ToastContainer />
      <div className="fixed inset-0  z-40 bg-customBlue bg-opacity-50"></div>
      <div
        className="fixed inset-0  z-50 flex items-center justify-center"
        onClick={closeModal} // Clicking on the background closes the modal
      >
        <div
          className="relative bg-white shadow-lg rounded-lg px-8 py-6 w-full max-w-md "
          onClick={(e) => e.stopPropagation()} // Prevent closing modal when clicking inside
        >
          {showErrorModal ? (
            errorModal({
              email: formData.email,
              firstName: formData.firstName,
              lastName: formData.lastName,
              error,
            })
          ) : showSuccessModal ? (
            confirmationModal({
              email: formData.email,
              firstName: formData.firstName,
              lastName: formData.lastName,
            })
          ) : (
            <>
              <div className="text-center">
                <h1 className="text-3xl md:text-4xl font-bold mb-4">
                  Join Our Waitlist
                </h1>
                <p className="text-gray-500 text-sm md:text-lg mb-4">
                  Be one of the first 100 to get an exclusive invite to our
                  community.
                </p>
              </div>
              <Page1
                formData={formData}
                handleChange={handleChange}
                nextStep={handleClick}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default WaitlistModal;
