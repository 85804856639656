const CustomFloatingInput = ({
  type,
  text,
  id,
  required,
  value,
  onChange,
  icon,
  isValid = true,
  togglePassword,
  disabled = false,
}) => {
  return (
    <div className="relative">
      <input
        type={type}
        id={id}
        value={value}
        onChange={onChange}
        className={`block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border ${
          isValid ? "border-gray-300" : "border-red-500"
        } appearance-none focus:outline-none focus:ring-0 ${
          isValid ? "focus:border-blue-600" : "focus:border-red-500"
        } peer ${disabled ? "opacity-70" : ""}`}
        placeholder=" "
        required={required}
        disabled={disabled}
      />
      {togglePassword && (
        <button
          onClick={togglePassword}
          type="button"
          className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
        >
          <i
            className={`fa-solid ${
              type === "password" ? "fa-eye" : "fa-eye-slash"
            }`}
          />
        </button>
      )}
      <label
        htmlFor={id}
        className="absolute ms-1 text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4"
      >
        {icon && <i className={icon + " pr-2"} />}
        {text}
      </label>
    </div>
  );
};

export default CustomFloatingInput;
