import { useState } from "react";
import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

const ChatTutorial = ({ showTutorial, setShowTutorial }) => {

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2800,
        arrows: false
    };

    if (!showTutorial) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 ">
            <div className="relative bg-white rounded-2xl shadow-xl p-8 max-w-md w-full md:max-w-2xl md:p-10 ">
                <button
                    onClick={() => setShowTutorial(false)}
                    className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 transition duration-300"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
                <h2 className="text-3xl font-extrabold mb-6 text-gray-800 text-center">Welcome to Chat With Character</h2>
                <Slider {...settings}>
                    <div>
                        <div className="text-lg text-gray-600 mb-4 text-center">
                            <div>Have a crush on a character ?</div>
                            <div>Do you want to chat with them?</div>

                        </div>
                    </div>
                    <div>
                        <div className="text-lg text-gray-600 mb-4 text-center">
                            <div>Have a question about your favorite show or book?</div>
                            <div>Why not ask the main character?</div>


                        </div>
                    </div>
                    <div>
                        <div className="text-lg text-gray-600 mb-4 text-center">
                            <div>Ever just wanted to talk to a character from a show or book?</div>
                            <div>Start a chat and find out what they're up to?</div>

                        </div>
                    </div>
                    <div>
                        <div className="text-lg text-gray-600 mb-4 text-center">
                            Why not just start a chat with your favorite character!
                        </div>
                    </div>
                </Slider>
                <div className="flex justify-center">
                    <button
                        onClick={() => setShowTutorial(false)}
                        className="bg-blue-600 text-white px-6 py-3 mt-6 rounded-lg hover:bg-blue-700 transition duration-300"
                    >
                        Start Chat!
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ChatTutorial;
