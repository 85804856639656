import React, { useEffect, useState } from "react";
import { getBookmarks, handleRemoveBookmark } from "../Books/BooksUtil";
import BookDetailComponent from "../Books/BookDetailComponent";
import { useNavigate } from "react-router-dom";
import Loading from "../Common/Loading";

const BookmarkComponent = ({ setSelectedTab }) => {
  const [bookmarks, setBookmarks] = useState([]);
  const [openBook, setOpenBook] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    setSelectedTab("bookmarks");
    getBookmarks(setBookmarks);
    setLoading(false);
  }, []);

  const orderBook = (book) => {
    if (book) {
      navigate(`/order?book_id=${book.id}`);
    }
  };

  return (
    <div className="flex flex-col h-full bg-gray-50 overflow-y-auto w-full md:rounded-tl-2xl">
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="w-full flex justify-start ps-4 border-b-2">
            <h1 className="text-xl md:text-2xl font-semibold text-center my-3">
              <i className="fa-solid fa-bookmark" /> Bookmarks
            </h1>
          </div>
          <div className="w-full flex flex-col p-4 rounded overflow-y-auto h-full">
            {bookmarks.length === 0 ? (
              <div className="flex items-center justify-center flex-col h-full">
                <p className="text-gray-800 text-2xl mb-4"> No bookmarks yet! </p>
                <p className="text-gray-600 text-lg">Discover and save the books you love. Happy reading!</p>
              </div>
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {bookmarks.map((book) => (
                  <div
                    key={book.id}
                    className="bg-white rounded-lg shadow p-4 flex flex-col"
                    role="button"
                    tabIndex="0"
                    onClick={() => setOpenBook(book)}
                    onKeyPress={(e) => e.key === "Enter" && setOpenBook(book)}
                  >
                    <div className="flex  justify-center">
                      <img
                        src={book.image_url}
                        alt={book.title}
                        className="w-1/3 md:w-1/3 h-auto rounded-b-lg md:rounded-b-none md:rounded-r-lg"
                      /></div>
                    <div className="mt-3">
                      <h2 className="text-xl font-semibold mb-2 truncate">
                        {book.title}
                      </h2>
                      <p className="text-gray-700">{book.author}</p>
                    </div>
                    <div className="mt-4 p-2 w-full flex justify-between gap-2">
                      <div className="  font-bold p-2 rounded text-sm " onClick={(event) => {
                        event.stopPropagation();
                        handleRemoveBookmark(book, setBookmarks);
                      }}>
                        <i className="fa-solid fa-trash text-gray-400 hover:text-gray-950  text-xl" />
                      </div>

                      <button
                        onClick={(event) => {
                          event.stopPropagation();
                          orderBook(book);
                        }}
                        className="flex-1 sm:flex-initial bg-green-500 hover:bg-green-600 text-white font-bold p-2 rounded shadow-lg text-sm transition-colors"
                        aria-label={`Rent ${book.title} now`}
                      >
                        Rent Now
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {openBook && (
              <BookDetailComponent
                book={openBook}
                onClose={() => setOpenBook(null)}
                isBookmarked={true}
                setBookmarks={setBookmarks}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default BookmarkComponent;
