import React from "react";

const Loading = () => {
  return (
    <div className="flex items-center justify-center h-full">
      <div className="animate-spin rounded-full h-24 w-24 md:h-32 md:w-32 border-t-4 border-b-4 border-gray-500"></div>
    </div>
  );
};

export default Loading;
