import queryString from "query-string";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { confirmEmail } from "./ConfirmEmailUtils";
import Loading from "../Common/Loading";

const ConfirmEmail = () => {
    const location = useLocation();
    const queryParams = queryString.parse(location.search);
    const token = queryParams.token || "";
    const [isVerified, setIsVerified] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        confirmEmail({ token, setIsVerified, setIsLoading });
    }, [token]);

    const handleClick = () => {
        navigate("/login");
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100 px-4">
            {isLoading ? (
                <Loading />
            ) : <>
                    {isVerified ? (
                        <div className="bg-white p-8 rounded-2xl shadow-2xl text-center w-full max-w-md">
                            <i className="fa fa-check-circle text-green-500 text-6xl mb-4"></i>
                            <h1 className="text-2xl font-bold mb-4">Email Confirmed</h1>
                            <p className="text-gray-700 mb-4">Your email has been confirmed. Please login.</p>
                            <p className="text-gray-700 mb-6">Happy Reading!</p>
                            <button
                                className="bg-blue-500 text-white px-4 py-2 rounded-3xl hover:bg-blue-700 transition inline-flex items-center"
                                onClick={handleClick}
                            >
                                Go to Login
                            </button>
                        </div>
                    ) : (
                        <div className="bg-white p-8 rounded-2xl shadow-2xl text-center w-full max-w-md">
                            <i className="fa fa-exclamation-circle text-red-500 text-6xl mb-4"></i>
                            <h1 className="text-2xl font-bold mb-4">Oops!</h1>
                            <p className="text-gray-700 mb-4">There was an issue confirming your email.</p>
                            <button
                                    className="bg-blue-500 text-white px-4 py-2 rounded-3xl hover:bg-blue-700 transition inline-flex items-center"
                                    onClick={handleClick}
                            >
                                Go to Login
                            </button>
                        </div>
                    )}</>}
            
        </div>
    );
};

export default ConfirmEmail;
