import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import Loading from "../Common/Loading";
import {
  fetchGetDataNoAuth,
  fetchPostDataNoAuth,
} from "../Utilities/FetchUtils";
import { ForgetPasswordRequest } from "../Serializers/SerializerModels";
import { isValidPassword } from "./SignupUtilities";
import CustomFloatingInput from "../Utilities/CustomFloatingInput";

const ForgotPasswordVerify = () => {
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const token = queryParams.resetToken || "";
  const [isVerified, setIsVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showModal, setShowModal] = useState(false); // Modal control state
  const [modalMessage, setModalMessage] = useState(""); // Modal message
  const [isSuccess, setIsSuccess] = useState(false); // Success or error modal flag
  const navigate = useNavigate();

  useEffect(() => {
    const verifyPasswordLink = async () => {
      setIsLoading(true);
      if (token) {
        const { response, error } = await fetchGetDataNoAuth(
          `user/verify_reset_link?token=${token}`
        );
        if (response) {
          setIsVerified(true);
        } else if (!error) {
          setErrorMessage("Invalid or expired reset link.");
          showModalWithRedirect("Invalid or expired reset link.", false);
        } else {
          const error_json = await error.json();
          setErrorMessage("Invalid or expired reset link.");
          showModalWithRedirect(
            error_json.message || "Invalid or expired reset link.",
            false
          );
        }
      } else {
        showModalWithRedirect("No reset token provided.", false);
      }
      setIsLoading(false);
    };
    verifyPasswordLink();
  }, [token]);

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    if (!isValidPassword(newPassword)) {
      setErrorMessage(
        "Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, and one number."
      );
      setIsLoading(false);
      return;
    }
    if (newPassword !== confirmPassword) {
      setErrorMessage("Passwords do not match.");
      setIsLoading(false);
      return;
    }

    const { response, error } = await fetchPostDataNoAuth(
      "user/reset_password",
      new ForgetPasswordRequest({
        newPassword,
        confirmPassword,
        resetToken: token,
      })
    );
    if (response) {
      showModalWithRedirect(
        "Password reset successful. Redirecting to login.",
        true
      );
    } else if (!error) {
      setErrorMessage("Failed to reset password.");
      showModalWithRedirect("Failed to reset password.", false);
    } else {
      const error_json = await error.json();
      setErrorMessage(error_json.message || "Failed to reset password.");
      showModalWithRedirect(
        error_json.message || "Failed to reset password.",
        false
      );
    }
    setIsLoading(false);
  };

  const showModalWithRedirect = (message, success) => {
    setModalMessage(message);
    setIsSuccess(success);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    navigate("/login"); // Redirect to login page
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 px-4">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {showModal ? (
            <>
              {" "}
              <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                <div className="bg-white rounded p-6 max-w-sm w-full">
                  <h2
                    className={`text-xl font-bold mb-4 ${
                      isSuccess ? "text-green-600" : "text-red-600"
                    }`}
                  >
                    {isSuccess ? "Success" : "Error"}
                  </h2>
                  <p className="mb-6">{modalMessage}</p>
                  <button
                    onClick={handleCloseModal}
                    className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                  >
                    Close
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="w-full max-w-md">
                <form
                  onSubmit={handleSubmit}
                  className="bg-white p-6 rounded shadow-md"
                >
                  <h2 className="text-2xl font-bold mb-4">
                    Reset Your Password
                  </h2>

                  <div className="mb-4">
                    <CustomFloatingInput
                      type={"password"}
                      id="newPassword"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      text="New Password"
                      icon="fa-solid fa-lock"
                      isValid={isValidPassword}
                      required={true}
                    />
                    {/* <label className="block text-gray-700">New Password</label>
                    <input
                      type="password"
                      value={newPassword}
                      onChange={}
                      className="w-full px-3 py-2 border rounded"
                      required
                    /> */}
                  </div>
                  <div className="mb-4">
                    <CustomFloatingInput
                      type={"password"}
                      id="confirmPassword"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      text="Confirm New Password"
                      icon="fa-solid fa-lock"
                      isValid={isValidPassword}
                      required={true}
                    />
                    {errorMessage && (
                      <p className="text-red-500">{errorMessage}</p>
                    )}
                  </div>

                  <button
                    type="submit"
                    className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                  >
                    Reset Password
                  </button>
                </form>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ForgotPasswordVerify;
