import { BlogModel } from "../Serializers/SerializerModels";
import { fetchGetDataNoAuth } from "../Utilities/FetchUtils";
export const fetchBlogs = async (setBlogData,setLoading,limit) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const response = await fetchGetDataNoAuth(`blog/get?limit=${limit}&offset=0`);
    const data = await response.response;
    setBlogData(data.map((data) => new BlogModel(data)));
    setLoading(false);
};

export const fetchBlogByTitleId = async (titleId, setBlogData) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const response = await fetchGetDataNoAuth(`blogget_by_title_id?blog_title=${titleId}`);
    const data = await response.response;
    setBlogData(new BlogModel(data));
};

export const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };

export const formatDescription = (description) => {
    const maxLength = 100;
    const t_desc = description.replace(/<[^>]+>/g, '');
    return truncateText(t_desc, maxLength);
  };

export const formatDate = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = new Date(date).toLocaleDateString('en-US', options);
    return formattedDate;
  };