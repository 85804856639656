import { useEffect, useState } from "react";
import blogImage from "../../Assets/Blog-image.jpg";
import { fetchBlogs, formatDescription, formatDate } from "./BlogsUtils";
import BlogCard from "./BlogCard";

const BlogCards = ({ blogData }) => {
  return (
    <>
      <div className="flex flex-col md:flex-row items-center justify-between mb-10 w-100">
        <div className="text-center  mb-6 lg:mb-0 lg:mr-10 w-1/2">
          <h1 className="text-4xl font-semibold font-serif">Readkar Blogs</h1>
          <p className="text-md text-gray-600 mt-2">
            Discover Blogs That Spark Your Interest!
          </p>
        </div>
        <div className="flex justify-center lg:justify-center w-full p-4 md:w-1/2">
          <img
            src={blogImage}
            alt="Illustration"
            className=" w-10/12 h-auto  object-cover"
          />
        </div>
      </div>
      <div className="border-t border-gray-300 mb-10 mx-4"></div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 px-8">
        {blogData.map((blog, index) => (
          <BlogCard
            key={index}
            title={blog.title}
            description={blog.description}
            date={blog.date}
            image={blog.image}
            url={blog.title_id}
          />
        ))}
      </div>
    </>
  );
};

export default BlogCards;
