import React, { useEffect, useState } from "react";
import { cancelOrder, getMyBooks, returnOrder } from "./MyBooksUtils";
import Loading from "../Common/Loading";
import { CancelOrderRequest } from "../Serializers/SerializerModels";
import { ToastContainer, toast } from "react-toastify";
import { format } from "date-fns"; // date formatting

const MyBooksComponent = ({ setSelectedTab }) => {
  const [myBooks, setMyBooks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setSelectedTab("books");
    getMyBooks({ setMyBooks, setIsLoading, setError });
  }, []);
  useEffect(() => {
    console.log(myBooks);
  }, [myBooks]);

  const handleCancelOrder = (book) => {
    setIsLoading(true);
    cancelOrder(new CancelOrderRequest(book.order_id), toast);
    setTimeout(() => {
      getMyBooks({ setMyBooks, setIsLoading, setError });
    }, 2000);
  };

  const handleReturnOrder = (book) => {
    setIsLoading(true);
    returnOrder(new CancelOrderRequest(book.order_id), toast);
    setTimeout(() => {
      getMyBooks({ setMyBooks, setIsLoading, setError });
    }, 2000);
  };

  const formatDate = (dateString) =>
    format(new Date(dateString), "dd MMM yyyy");

  return (
    <div className="flex flex-col w-full h-full bg-gray-50 overflow-y-auto md:rounded-tl-2xl">
      <ToastContainer />
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="w-full flex justify-start ps-4 border-b-2">
            <h1 className="text-xl md:text-2xl font-semibold text-center my-3">
              <i className="fa-solid fa-book" /> My Books
            </h1>
          </div>

          <div className="w-full flex flex-col items-center p-4 rounded overflow-y-auto overflow-x-clip">
            {myBooks.length ? (
              myBooks.map((book) => (
                <div
                  key={book.book_id}
                  className="m-2 bg-white rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300 ease-in-out flex flex-col w-full max-w-4xl"
                >
                  <div className="p-2 text-sm md:text-md w-full text-gray-600 flex justify-between items-center bg-slate-100 rounded-t-xl">
                    <div>{book.order_status}</div>
                    <div className="text-gray-400">
                      Updated: {formatDate(book.order_updated_at)}
                    </div>
                  </div>

                  <div className="flex flex-row items-center p-4 space-x-4">
                    <div className="p-2">
                      <img
                        className="h-36 w-36 object-cover rounded-xl transition-transform duration-300 ease-in-out transform hover:scale-105"
                        src={book.image_url}
                        alt={book.book_name}
                      />
                    </div>

                    <div className="flex flex-col flex-grow space-y-2">
                      <div className="text-lg md:text-xl font-semibold text-gray-800">
                        {book.book_name}
                      </div>
                      <div className="text-sm md:text-md text-gray-500">
                        by {book.book_author}
                      </div>
                      <div className="text-xs md:text-sm text-gray-400">
                        Order Id: {book.book_id}
                      </div>
                      <div className="text-xs md:text-sm text-gray-400">
                        Ordered Date: {formatDate(book.order_created_at)}
                      </div>
                    </div>

                    {book.order_status === "To Be Delivered" && (
                      <button
                        className="ml-4 px-4 py-2 text-sm bg-red-500 text-white rounded-lg hover:bg-red-600 transition-all duration-200 ease-in-out transform hover:scale-105"
                        onClick={() => handleCancelOrder(book)}
                      >
                        Cancel Order
                      </button>
                    )}

                    {book.order_status === "Delivered" && (
                      <button
                        className="ml-4 px-4 py-2 text-sm bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-all duration-200 ease-in-out transform hover:scale-105"
                        onClick={() => handleReturnOrder(book)}
                      >
                        Return Order
                      </button>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div className="flex items-center justify-center flex-col h-full">
                <p className="text-gray-800 text-2xl mb-4">
                  No books rented yet!
                </p>
                <p className="text-gray-600 text-lg">
                  Start exploring the world of books. Happy reading!
                </p>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default MyBooksComponent;
