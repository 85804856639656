import { useEffect, useState } from "react";
import { fetchBlogByTitleId, formatDate } from "./BlogsUtils"; // Ensure this function is properly defined and imported
import parse from "html-react-parser"; // Parses HTML content safely
import BlogCard from "./BlogCard";

const BlogDetails = ({ titleId, blogCardData, blogData }) => {
  if (!blogData) {
    return (
      <div className="p-4 w-full h-full flex justify-center items-center">
        No blog data available.
      </div>
    );
  }

  return (
    <div className="p-4 bg-white rounded-lg lg:p-10">
      {/* Image and Meta Container */}
      <div className="mb-4">
        {blogData.image && (
          <img
            src={blogData.image}
            alt="Blog"
            className="w-full h-64 lg:h-96 object-cover rounded-lg"
            lazy="loading"
          />
        )}
      </div>

      <div className="flex flex-col lg:flex-row">
        {/* Main Content */}
        <div className="mb-4 lg:mb-0 lg:w-9/12 pe-8">
          <div className="py-4">
            <h1 className="text-3xl font-bold mb-2">{blogData.title}</h1>
            <div className="flex items-center text-gray-900 mb-1 space-x-4">
              <p className="font-semibold">{blogData.author}</p>
              <p className="text-gray-600">{formatDate(blogData.date)}</p>
            </div>
            <div className="border-t border-gray-100 my-4"></div>
          </div>
          <div className="text-gray-700">{parse(blogData.description)}</div>
        </div>

        {/* Divider for larger screens */}
        <div className="border-t lg:border-l border-gray-300 my-4 lg:my-0 lg:mx-4"></div>

        {/* Sidebar for New Blogs */}
        <div className="w-full lg:w-3/12 overflow-auto">
          <h2 className="text-3xl font-semibold mb-4">New Blogs</h2>
          <div className="flex flex-col space-y-4">
            {blogCardData.length > 0 ? (
              blogCardData.map((blog, index) => (
                <BlogCard
                  key={blog.title_id || index}
                  title={blog.title}
                  description={blog.description}
                  date={blog.date}
                  image={blog.image}
                  url={blog.title_id}
                />
              ))
            ) : (
              <p className="text-gray-600">No new blogs available.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;
