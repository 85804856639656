import { SubscribeRequest, Subscriptions, UserObject, UserSubscription } from "../Serializers/SerializerModels";
import { fetchGetData, fetchPostData } from "../Utilities/FetchUtils";
import { load } from '@cashfreepayments/cashfree-js';
export const getCurrentUser = async ({ setUserInfo, setError }) => {
  const { response } = await fetchGetData("user/get_current_user");
  if (response) {
    setUserInfo(new UserObject(response));
  } else {
    setError("Errot occured while fetching user. Please try again");
  }
};

export const getSubscriptionDetails = async ({
  setSubscriptionDetail,
  setIsLoading
}) => {
  setIsLoading(true);
  const { response } = await fetchGetData("subscription/my_subscription");
  if (response) {
    if (response.length !== 0) {
      setSubscriptionDetail(new UserSubscription(response[0]));
    } else {
      setSubscriptionDetail(null);
    }
  } else {
  }
  setIsLoading(false);
};

export const handleLogout = async (navigate, setIsUserLoggedIn) => {
  setIsUserLoggedIn(false);
  localStorage.clear();
  navigate("/login");
};

export const changePassword = async ({
  changePasswordRequest,
  setErrorMessage,
  setSuccessMessage,
}) => {
  const { response, error } = await fetchPostData(
    "user/change_password",
    changePasswordRequest
  );
  if (response) {
    setSuccessMessage(true);
  } else {
    setErrorMessage("Current Password is incorrect. Please try again");
  }
};


export const copyToClipboard = async (text) => {
  if (navigator.clipboard) {
    // Modern async clipboard API
    try {
      await navigator.clipboard.writeText(text);
      alert("Invite code copied!");
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  } else {
    // Fallback for older browsers
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand("copy");
      alert("Invite code copied!");
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
    document.body.removeChild(textArea);
  }
};


export const getSubscriptions = async (setSubscriptionDetail) => {
  const { response } = await fetchGetData("subscription/get_all_plans");
  if (response) {
    const subscriptions = await response.map((data) => new Subscriptions(data));
    setSubscriptionDetail(subscriptions);

  }
  else {
    setSubscriptionDetail([]);
  }
}


export const userSubscription = async (plan) => {
  const { response } = await fetchPostData("subscription/subscribe", new SubscribeRequest(plan.subscription_id));
  if (response) {
    initiatePayment(response);
  }
  
}

export const initiatePayment = async (session_id) => {
  const cashfree = await load({
    mode: "sandbox"
  })
  let checkoutOptions = {
    paymentSessionId: session_id,
    redirectTarget: "_self" //optional ( _self, _blank, or _top)
  }

  cashfree.checkout(checkoutOptions)
}